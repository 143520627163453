import React, { Fragment } from 'react';
import UserTimeline from '../../Common/UserTimeline';
import CatList from '../../Common/CatList';

const Catchies = ({ user }) =>
    <Fragment>
        <div className="main container">
            <div className="timeline">
                <UserTimeline user={user} activeMenu="catchies" />
                <div id="page-contents">
                    <div className="row">
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-9">
                            <div className="about-profile">
                                <div className="about-content-block">
                                    <img alt="catchies" className="catchies-icon" src="/images/ribbon.png" />
                                    <h4 className="grey">
                                        Catchies
                                    </h4>
                                    <div className="line"></div>
                                    <CatList cats={user.cats.filter((cat) => cat.deathdate)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>

export default Catchies;
