import React, { useEffect, useState } from 'react';
import CatModal from './CatModal';
import { ruleRunner, run } from '../../../utils/validation';
import { required } from '../../../utils/validation/rules';
import { padLeft } from '../../../utils/number';
import { getCountries } from '../../../actions/country';
import { addEditCat } from '../../../actions/cat';
import { catLink } from '../../../utils/link';
import { useHistory } from 'react-router-dom';

const emptyModel = {
    name: '',
    breed: '',
    hasPassedAway: false,
    location: '',
    city: ''
}

function CatModalContainer(props) {
    const [model, setModel] = useState(emptyModel);
    const [countries, setCountries] = useState([]);
    const [validationErrors, setValidationErrors] = useState();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const countries = await getCountries();
            setCountries(countries);
        })();
    }, []);

    useEffect(() => {
        const model = getCatModel(props.cat || emptyModel);
        setModel(model);
    }, [props.cat]);

    function handleChange(field, value) {
        const inputValue = value.target.type === 'checkbox' ? value.target.checked : value.target.value;
        setModel({
            ...model,
            [field]: inputValue
        });
    }

    async function onSaveClick() {
        const validationErrors = run(model, fieldValidations);

        if (Object.keys(validationErrors).length > 0) {
            setValidationErrors(validationErrors);
        }
        else {
            if (!model.isMale && !model.isFemale) {
                setValidationErrors({ gender: 'Please choose your cat gender' });
            } else if (model.hasPassedAway && (!model.deathDay || !model.deathMonth || !model.deathYear)) {
                setValidationErrors({ deathdate: 'Please select death date' });
            }
            else {
                setValidationErrors(null);
                const newCat = await addEditCat(model);
                if (newCat) {
                    window.hideModal();
                    history.push(catLink(newCat));
                    window.location.reload();
                }
            }
        }
    }

    function getCatModel(cat) {
        cat.isMale = cat.gender ? 'on' : null;
        cat.isFemale = !cat.gender ? 'on' : null;

        if (cat.deathdate) {
            const deathdate = new Date(cat.deathdate);
            cat.deathDay = padLeft(deathdate.getDate());
            cat.deathMonth = padLeft(deathdate.getMonth() + 1);
            cat.deathYear = deathdate.getFullYear();
        }

        cat.countryId = cat.country ? cat.country.id : '';
        cat.city = cat.city || '';

        cat.hasPassedAway = cat.deathdate ? true : false;

        cat.breed = cat.breed || '';
        cat.location = cat.location || '';

        cat.birthDay = cat.birthDay ? padLeft(cat.birthDay) : '';
        cat.birthMonth = cat.birthMonth ? padLeft(cat.birthMonth) : '';
        cat.birthYear = cat.birthYear || '';

        return cat;
    }

    return (
        <CatModal
            cat={model}
            handleChange={handleChange}
            onSaveClick={onSaveClick}
            validationErrors={validationErrors}
            countries={countries}
        />
    )
}

const fieldValidations = [
    ruleRunner("name", "Name", required)
];

export default CatModalContainer;
