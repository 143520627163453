import React, { useContext, useEffect, useState } from 'react';
import { ruleRunner, run } from '../../../utils/validation';
import { required } from '../../../utils/validation/rules';
import SettingsModal from './SettingsModal';
import { userLink } from '../../../utils/link';
import { editUser } from '../../../actions/user';
import { getCountries } from '../../../actions/country';
import { useHistory } from 'react-router-dom';
import Context from '../../../context';

const emptyState = {
    firstname: '',
    lastname: '',
    city: ''
};

function SettingsModalContainer() {
    const [model, setModel] = useState(emptyState);
    const [countries, setCountries] = useState([]);
    const [validationErrors, setValidationErrors] = useState();
    const history = useHistory();
    const { current } = useContext(Context)

    useEffect(() => {
        (async () => {
            const countries = await getCountries();
            setCountries(countries);
            const { id, firstname, lastname, country, city } = current;
            setModel({
                id,
                firstname,
                lastname,
                countryId: country.id,
                city: city || ''
            });
        })();
    }, [current]);

    function onChange(field, value) {
        setModel({
            ...model,
            [field]: value.target.value
        });
    }

    async function onSave() {
        const newValidationErrors = run(model, fieldValidations);

        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        }
        else {
            setValidationErrors(null);
            const response = await editUser(model);
            if (response) {
                window.hideModal();
                history.push(userLink(current));
                window.location.reload();
            }
        }
    }

    return (
        <SettingsModal
            user={model}
            validationErrors={validationErrors}
            onChange={onChange}
            onSave={onSave}
            countries={countries} />
    )
}

const fieldValidations = [
    ruleRunner("firstname", "Firstname", required),
    ruleRunner("lastname", "Lastname", required)
];

export default SettingsModalContainer;
