import React, { useContext, useEffect, useState } from 'react';
import { getUser, getFavorites, deleteFavorite, addUserFavorite } from '../../../actions/user';
import Information from './Information';
import { userLink } from '../../../utils/link';
import Scrollable from '../../Common/Scrollable';
import { Redirect, useParams } from 'react-router-dom';
import Context from '../../../context';
import Preloader from '../../Common/PreLoader';

function InformationContainer() {
    let { id } = useParams();
    const { current } = useContext(Context);
    const [user, setUser] = useState();
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        (async () => {
            if (id) {
                const user = await getUser(id, current);
                setUser(user);
                const newFavorites = await getFavorites(id);
                setFavorites(newFavorites);
            }
        })();
    }, [id, current]);

    async function handleDelete(photoId) {
        await deleteFavorite(photoId);
        window.hideModal();
        setFavorites(favorites.filter((item) => item.id !== photoId));
    }

    async function handleFavoriteSave(profilePhoto) {
        const newFavorite = await addUserFavorite(profilePhoto);
        setFavorites([
            newFavorite,
            ...favorites
        ]);
    }

    async function getNewFavorites(index = 0) {
        const fetchedFavorites = await getFavorites(id, index);
        setFavorites(favorites => [
            ...favorites,
            ...fetchedFavorites
        ]);
    }

    if (!id) {
        return <Redirect to={userLink(current)} />
    }

    if (!user) {
        return <Preloader />;
    }

    return (
        <Scrollable count={favorites.length} callback={getNewFavorites}>
            <Information
                user={user}
                favorites={favorites}
                setFavorites={setFavorites}
                handleDelete={handleDelete}
                handleFavoriteSave={handleFavoriteSave} />
        </Scrollable>
    )
}

export default InformationContainer;