import React, { useState } from 'react';
import { changePasswordWelcome } from '../../../actions/user';
import ChangePassword from './ChangePassword';
import { ruleRunner, run } from '../../../utils/validation';
import { required, minLength, mustMatch } from '../../../utils/validation/rules';
import { useHistory } from 'react-router-dom';

function ChangePasswordContainer(props) {
    const [model, setModel] = useState({});
    const [validationErrors, setValidationErrors] = useState();
    const history = useHistory();

    function handleChange(field, value) {
        setModel({
            ...model,
            [field]: value.target.value
        });
    }

    async function onChangePasswordClick() {
        const newValidationErrors = run(model, fieldValidations);

        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        }
        else {
            setValidationErrors(null);
            const response = await changePasswordWelcome(props.token, model.password);
            if (response) {
                history.push('/welcome');
            }
        }
    }

    return (
        <ChangePassword
            handleChange={handleChange}
            onChangePasswordClick={onChangePasswordClick}
            validationErrors={validationErrors} />
    )
}

const fieldValidations = [
    ruleRunner("password", "Password", required, minLength(3)),
    ruleRunner("password2", "Password confirmation", required, mustMatch("password", "Password")),
];

export default ChangePasswordContainer;