import { staticUrl } from '../configuration';

export const catProfile = (cat) => {
    return cat.profilePhoto ? `${staticUrl}/${cat.user.id}/${cat.profilePhoto}` : '/images/cat-profile.png';
}

export const userProfile = (user) => {
    return user.profilePhoto ? `${staticUrl}/${user.id}/${user.profilePhoto}` : '/images/default-profile.jpg';
}

export const userStaticPath = (user) => {
    return `${staticUrl}/${user.id}`;
}