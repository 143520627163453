import React from 'react';
import PhotoModal from '../Common/PhotoModal';
import MemberOnly from '../Common/MemberOnly';

const CreatePost = ({ profilePhoto, onInputChange, message, onPublish, onPhotoSave }) =>
    <MemberOnly hide="true">
        <div className="create-post">
            <div className="row">
                <div className="col-md-8 col-sm-8">
                    <div className="form-group">
                        <img src={profilePhoto} alt="" className="profile-photo-md" />
                        <textarea onChange={value => onInputChange('message', value)} value={message} rows="5" name="texts" className="form-control" placeholder=""></textarea>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4">
                    <div className="tools">
                        <ul className="publishing-tools list-inline">
                            <li>
                                <a href="#/" data-toggle="modal" data-target=".add-feed-photo-modal"><i className="ion-images"></i></a>
                            </li>
                        </ul>
                        <button onClick={onPublish} className="btn btn-primary pull-right">Publish</button>
                    </div>
                </div>
            </div>
            <PhotoModal
                modalClass="add-feed-photo-modal"
                onSave={onPhotoSave} />
        </div>
    </MemberOnly>

export default CreatePost;
