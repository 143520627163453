import React, { useContext, useEffect, useState } from 'react';
import CatTimeline from './CatTimeline';
import { diaryType } from '../../../utils/enum';
import Context from '../../../context';
import { addProfilePhoto } from '../../../actions/cat';

function CatTimelineContainer(props) {
    const { current } = useContext(Context);
    const [cat, setCat] = useState();

    useEffect(() => {
        setCat(props.cat);
    }, [props.cat]);

    function hasDiaryVisible() {
        if (cat.user.id === current.id) {
            return true;
        }
        if (cat.diaryType === diaryType.everyone) {
            return true;
        }
        if (cat.diaryType === diaryType.catRep) {
            if (isCatRepFriend()) {
                return true;
            }
        }
        return false;
    }

    function isCatRepFriend() {
        if (current.friends.filter((user) => user.id === cat.user.id).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    async function onSaveProfilePhoto(profilePhoto) {
        const updatedCat = await addProfilePhoto(cat.id, profilePhoto);
        setCat({
            ...cat,
            profilePhoto: updatedCat.profilePhoto
        });
    }

    if (!cat) {
        return null;
    }

    return (
        <CatTimeline
            cat={cat}
            activeMenu={props.activeMenu}
            hasDiaryVisible={hasDiaryVisible()}
            isCatRepFriend={isCatRepFriend()}
            onSaveProfilePhoto={onSaveProfilePhoto} />
    )
}

export default CatTimelineContainer;
