import React from 'react';
import CatTimeline from '../../Common/CatTimeline';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { userLink } from '../../../utils/link';
import UserCard from '../../Common/UserCard';
import CatModal from '../../Common/CatModal';
import MemberOnly from '../../Common/MemberOnly';

const Information = ({ cat }) => (
    <>
        <div className="main container">
            <div className="timeline">
                <CatTimeline cat={cat} activeMenu="about" />
                <div id="page-contents">
                    <div className="row">
                        <div className="col-md-3 static">
                            <ul className="edit-menu">
                                <li className="active"><i className="icon ion-ios-information-outline"></i>
                                    <Link to={userLink(cat.user)}>Back to CatRep</Link>
                                </li>
                                {cat.isMine &&
                                    <li id="catsettings-link"><i className="icon ion-ios-information-outline"></i>
                                        <MemberOnly>
                                            <a href="#/" data-toggle="modal" data-target=".add-cat-modal">Cat Settings</a>
                                        </MemberOnly>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="edit-profile-container">
                                <div className="block-title">
                                    <h4 className="grey"><i className="icon ion-android-checkmark-circle"></i>CatID</h4>
                                    <div className="line"></div>
                                </div>
                                <div className="edit-block">
                                    <form name="basic-info" id="basic-info" className="form-inline">
                                        <div className="row">
                                            <div className="form-group">
                                                <span className="custom-label inline-label"><strong>Name: </strong></span>
                                                <label className="radio-inline">
                                                    {cat.name}
                                                </label>
                                            </div>
                                        </div>
                                        {(cat.birthDay && cat.birthMonth && cat.birthYear) && <div className="row">
                                            <div className="form-group">
                                                <span className="custom-label inline-label"><strong>Date of birth: </strong></span>
                                                <label className="radio-inline">
                                                    {moment(`${cat.birthYear}/${cat.birthMonth}/${cat.birthDay}`).format('DD/MM/YYYY')}
                                                </label>
                                            </div>
                                        </div>}
                                        {!cat.birthDay && !cat.birthMonth && cat.birthYear && <div className="row">
                                            <div className="form-group">
                                                <span className="custom-label inline-label"><strong>Date of birth: </strong></span>
                                                <label className="radio-inline">
                                                    {cat.birthYear}
                                                </label>
                                            </div>
                                        </div>}
                                        {!cat.birthDay && cat.birthMonth && cat.birthYear && <div className="row">
                                            <div className="form-group">
                                                <span className="custom-label inline-label"><strong>Date of birth: </strong></span>
                                                <label className="radio-inline">
                                                    {`${cat.birthMonth} - ${cat.birthYear}`}
                                                </label>
                                            </div>
                                        </div>}
                                        {!cat.birthYear && cat.birthDay && cat.birthMonth && <div className="row">
                                            <div className="form-group">
                                                <span className="custom-label inline-label"><strong>Date of birth: </strong></span>
                                                <label className="radio-inline">
                                                    {`${cat.birthDay} - ${cat.birthMonth}`}
                                                </label>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="form-group">
                                                <span className="custom-label inline-label"><strong>Gender: </strong></span>
                                                <label className="radio-inline">
                                                    {cat.gender ? "Male" : "Female"}
                                                </label>
                                            </div>
                                        </div>
                                        {cat.country && <div className="row">
                                            <div className="form-group">
                                                <span className="custom-label inline-label"><strong>Place of birth: </strong></span>
                                                <label className="radio-inline">
                                                    {cat.country.title} {cat.city ? `- ${cat.city}` : ''}
                                                </label>
                                            </div>
                                        </div>}
                                        {cat.location && <div className="row">
                                            <div className="form-group">
                                                <span className="custom-label inline-label"><strong>Location: </strong></span>
                                                <label className="radio-inline">
                                                    {cat.location}
                                                </label>
                                            </div>
                                        </div>}
                                        {cat.breed && <div className="row">
                                            <div className="form-group">
                                                <span className="custom-label inline-label"><strong>Breed: </strong></span>
                                                <label className="radio-inline">
                                                    {cat.breed}
                                                </label>
                                            </div>
                                        </div>}
                                        {cat.deathdate &&
                                            <div className="row">
                                                <div className="form-group">
                                                    <span className="custom-label inline-label"><strong>Date of death: </strong></span>
                                                    <label className="radio-inline">
                                                        {moment(cat.deathdate).format('DD/MM/YYYY')}
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 static">
                            <UserCard user={cat.user} cat={cat} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CatModal cat={cat} />
    </>
)

export default Information;
