import React from 'react';
import ProfileCard from '../Common/ProfileCard';
import CreatePost from '../Common/CreatePost';
import PostContent from '../Common/PostContent';
import { userProfile } from '../../utils/photo';
import { isAnonymous } from '../../utils/link';

const Feed = ({ current, feeds, onInputChange, message, onPublish, onPhotoSave, onEdit, onDelete, isFixed }) =>
    <div className="main">
        <div id="page-contents">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 static">
                        <ProfileCard user={current} />
                        {isAnonymous() &&
                            <>
                                <div className={isFixed ? 'fixed-banner' : 'banner'}>
                                    <a href="/welcome">
                                        <img src="/images/BannerWhisker.svg" alt="Register and open a CatRep Account!" />
                                    </a>
                                </div>
                            </>
                        }
                    </div>
                    <div className="col-md-7">
                        <h4 className="grey">
                            <i className="icon ion-android-checkmark-circle"></i> CatShare
                        </h4>
                        <div className="line"></div>
                        <CreatePost
                            profilePhoto={userProfile(current)}
                            onInputChange={onInputChange}
                            message={message}
                            onPublish={onPublish}
                            onPhotoSave={onPhotoSave} />
                        <div id="cat-share">
                            {feeds.map((feed) => {
                                return (
                                    <PostContent
                                        key={feed.id}
                                        feed={feed}
                                        onEdit={onEdit}
                                        onDelete={onDelete}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-md-2 static"></div>
                </div>
            </div>
        </div>
    </div>

export default Feed;
