import React, { useContext, useEffect, useState } from 'react';
import UserList from './UserList';
import { userLink } from '../../../utils/link';
import { getFriends, getRequested, addRelation, approveRelation, getWaiting } from '../../../actions/user';
import { useHistory } from 'react-router-dom';
import Context from '../../../context';

function UserListContainer(props) {
    const { user } = props;
    const history = useHistory();
    const [friends, setFriends] = useState([]);
    const [requested, setRequested] = useState([]);
    const [waiting, setWaiting] = useState([]);
    const { current, setCurrent } = useContext(Context);

    useEffect(() => {
        (async () => {
            const fetchedFriends = await getFriends(user.id);
            setFriends(fetchedFriends);
            const fetchedRequested = await getRequested(user.id);
            setRequested(fetchedRequested);
            const fetchedWaiting = await getWaiting(user.id);
            setWaiting(fetchedWaiting);
        })();
    }, [user]);

    function onvisit(user) {
        history.push(userLink(user));
    }

    async function onConnect(user) {
        const toUserId = await addRelation(user.id);
        const userItem = props.users.find((item) => item.id === toUserId);
        setRequested([...requested, userItem]);
    }

    async function onApprove(user) {
        const toUserId = await approveRelation(user.id);
        const userItem = waiting.find((item) => item.id === toUserId)
        setWaiting(waiting.filter((item) => item.id !== userItem.id));
        setFriends([...friends, userItem]);
        setCurrent({
            ...current,
            waiting: current.waiting.filter((item) => item.id !== userItem.id)
        });
    }

    function normalize(users) {
        return users.map((userItem) => {
            userItem.notConnected = false;
            userItem.isRequested = false;
            userItem.isWaiting = false;

            if (friends.find((u) => u.id === userItem.id)) {
                userItem.isFriend = true;
            } else if (requested.find((u) => u.id === userItem.id)) {
                userItem.isRequested = true;
            } else if (waiting.find((u) => u.id === userItem.id)) {
                userItem.isWaiting = true;
            } else {
                userItem.notConnected = true;
            }

            return userItem;
        });
    }

    return (
        <UserList
            users={normalize(props.users)}
            onVisit={onvisit}
            onConnect={onConnect}
            onApprove={onApprove} />
    )
}

export default UserListContainer;
