import React from 'react';
import Validation from '../../Common/Validation';

const ForgotPasswordModal = ({ onChange, onSendClick, validationErrors }) =>
    <div className="modal fade forgot-password" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title">Forgot my password</h4>
                </div>
                <div className="modal-body">
                    <form name="settings-form" className="modal-form">
                        <div className="form-group">
                            <label>* Your email</label>
                            <input onChange={onChange} className="form-control" type="text" placeholder="Your email" />
                        </div>
                    </form>
                    <Validation validationErrors={validationErrors} />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={onSendClick}>Send Email</button>
                </div>
            </div>
        </div>
    </div>

export default ForgotPasswordModal;