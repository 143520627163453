import axios from 'axios';

export const injectAuthorizationToken = () => {
    axios.defaults.headers.common['X-Authorization'] = '';
    delete axios.defaults.headers.common['X-Authorization'];

    const token = window.localStorage.getItem('token');
    if (token) {
        axios.defaults.headers.common['X-Authorization'] = `${token}`;
    }
}