import React, { useContext, useEffect, useState } from 'react';
import PostContent from './PostContent';
import { catProfile, userProfile } from '../../../utils/photo';
import { catLink, userLink } from '../../../utils/link';
import { addComment, likeFeed } from '../../../actions/feed';
import Context from '../../../context';

function PostContentContainer(props) {
    const [comment, setComment] = useState('');
    const [feed, setFeed] = useState();
    const { current } = useContext(Context);

    useEffect(() => {
        setFeed(props.feed);
    }, [props.feed]);

    function onInputChange(event) {
        setComment(event.target.value);
    }

    async function onLike(feedId) {
        const likeCount = await likeFeed(feedId);
        setFeed({
            ...feed,
            likes: likeCount
        });
    }

    async function onAddComment(feedId) {
        const content = { message: comment }
        const addedComment = await addComment(feedId, content);
        setFeed({
            ...feed,
            comments: [
                ...feed.comments,
                addedComment
            ]
        });
        setComment('');
    }

    function normalizeFeed(feed) {
        return {
            ...feed,
            profile: getProfile(feed)
        }
    }

    function getProfile(feed) {
        if (feed.cat) {
            return {
                isCat: true,
                name: feed.cat.name,
                profilePhoto: catProfile(feed.cat),
                profileLink: catLink(feed.cat)
            }
        } else {
            return {
                name: `${feed.user.firstname} ${feed.user.lastname}`,
                profilePhoto: userProfile(feed.user),
                profileLink: userLink(feed.user)
            }
        }
    }

    if (!feed) {
        return null;
    }

    return (
        <PostContent
            current={current}
            feed={normalizeFeed(feed)}
            onLike={onLike}
            onAddComment={onAddComment}
            onInputChange={onInputChange}
            commentMessage={comment}
            onEdit={props.onEdit}
            onDelete={props.onDelete} />
    )
}
export default PostContentContainer;
