import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import UserTimeline from '../../Common/UserTimeline';
import UserList from '../../Common/UserList';

const FriendList = ({ user, friends }) =>
    <Fragment>
        <div className="main container">
            <div className="timeline">
                <UserTimeline user={user} activeMenu="friends" />
                <div id="page-contents">
                    <div className="row">
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-9">
                            <h4 className="grey">
                                <i className="icon ion-android-checkmark-circle"></i> CatReps
                            </h4>
                            <div className="line"></div>
                            {friends.length <= 0 && <Fragment>
                                {user.isMe ?
                                    <Fragment>
                                        <p>You didn't connect with any CatRep yet.</p>
                                        <p><Link to={`/new-cat-reps`}>Would you like to discover new CatReps?</Link></p>
                                    </Fragment>
                                    :
                                    <p>User didn't connect with any CatRep yet</p>
                                }
                            </Fragment>}
                            <UserList user={user} users={friends} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>

export default FriendList;
