import React from 'react';
import { Link } from 'react-router-dom';
import CatTimeline from '../../Common/CatTimeline';
import { catProfile } from '../../../utils/photo';
import CreatePost from '../../Common/CreatePost';
import PostContent from '../../Common/PostContent';
import { userLink } from '../../../utils/link';

const Blog = ({ cat, blogs, onPhotoSave, onInputChange, onPublish, message, onEdit, onDelete }) =>

    <div className="main container">
        <div className="timeline">
            <CatTimeline cat={cat} activeMenu="blog" />
            <div id="page-contents">
                <div className="row">
                    <div className="col-md-3 static">
                        <ul className="edit-menu">
                            <li className="active"><i className="icon ion-ios-information-outline"></i>
                                <Link to={userLink(cat.user)}>Back to CatRep</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-7">
                        <h4 className="grey">
                            <i className="ion-ios-heart-outline icon-in-title"></i>CatBlog
                        </h4>
                        <div className="line"></div>
                        {cat.isMine &&
                            <CreatePost
                                profilePhoto={catProfile(cat)}
                                onInputChange={onInputChange}
                                message={message}
                                onPublish={onPublish}
                                onPhotoSave={onPhotoSave} />
                        }
                        <div id="cat-diary">
                            {blogs.map((blog) => {
                                return (
                                    <PostContent
                                        key={blog.id}
                                        feed={blog}
                                        onEdit={onEdit}
                                        onDelete={onDelete} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


export default Blog;
