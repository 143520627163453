import React from 'react';
import { Link } from 'react-router-dom';
import { userProfile } from '../../utils/photo';
import { userLink, isDefaultUser } from '../../utils/link';

const ProfileCard = ({ user }) =>
    <div className="profile-card">
        <img src={userProfile(user)} alt="user" className="profile-photo" />
        <h5>
            <Link to={userLink(user)} className="text-white">
                {user.firstname} {user.lastname}
            </Link>
        </h5>
        {!isDefaultUser(user) && <a href="#/" className="text-white">
            <i className="ion ion-map"></i> {user.country.title} {user.city ? `- ${user.city}` : ''}
        </a>}
    </div>

export default ProfileCard;