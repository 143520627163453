export function ofCat(cat) {
    if (!cat || !cat.name) {
        return '';
    }
    return cat.name.endsWith('s') ? `${cat.name}'` : `${cat.name}'s`;
}

export function ofUser(user) {
    if (!user || !user.firstname) {
        return '';
    } 
    return user.firstname.endsWith('s') ? `${user.firstname}'` : `${user.firstname}'s`;
}