import React, { Fragment } from 'react';
import CatTimeline from '../../Common/CatTimeline';
import { Link } from 'react-router-dom';
import CatList from '../../Common/CatList';
import { userLink } from '../../../utils/link';

const FriendList = ({ cat, friends }) => (
    <Fragment>
        <div className="main container">
            <div className="timeline">
                <CatTimeline cat={cat} activeMenu="friends" />
                <div id="page-contents">
                    <div className="row">
                        <div className="col-md-3 static">
                            <ul className="edit-menu">
                                <li className="active"><i className="icon ion-ios-information-outline"></i>
                                    <Link to={userLink(cat.user)}>Back to CatRep</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-9" id="cat-mates">
                            <h4 className="grey">
                                <i className="ion-ios-heart-outline icon-in-title"></i>CatMates
                            </h4>
                            <div className="line"></div>
                            <CatList cats={friends} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
)

export default FriendList;
