import React from 'react';

const Header = () =>
    <header id="header-inverse">
        <nav>
            <div className="container">
                <div className="logo-container">
                    <a className="logo" href="/"><img className="img-responsive" src="images/logo-transparent.png" alt="logo" /></a>
                    <span></span>
                </div>
            </div>
        </nav>
    </header>

export default Header;