import React, { Fragment } from 'react';
import CatTimeline from '../../Common/CatTimeline';
import PhotoModal from '../../Common/PhotoModal';
import VideoModal from '../../Common/VideoModal';
import { userStaticPath } from '../../../utils/photo';
import { Link } from 'react-router-dom';
import { userLink } from '../../../utils/link';
import YoutubeModal from '../../Common/YoutubeModal';
import Masonry from '../../Common/Masonry';
import MemberOnly from '../../Common/MemberOnly';
import { catalogVideosEnabled } from '../../../configuration';

const AddPhotoButton = ({ cat, current }) => (
    <div style={{ textAlign: "center" }}>
        {cat.user.id === current.id &&
            <MemberOnly>
                <button className="btn-primary" data-toggle="modal" data-target=".add-catalog-modal">Add Photo</button>
            </MemberOnly>
        }
    </div>
)

const Catalog = ({ cat, current, videos, youtubeUrls, photos, handleDelete, fileType, handleDeleteVideo, handleDeleteYoutube, onPhotoSave }) => (
    <Fragment>
        <div className="main container">
            <div className="timeline">
                <CatTimeline cat={cat} activeMenu="catalog" />
                <div id="page-contents">
                    <div className="row">
                        <div className="col-md-3 static">
                            <ul id="catalog-list" className="nav-news-feed edit-menu">
                                <li className="active"><i className="icon ion-ios-information-outline"></i>
                                    <Link to={userLink(cat.user)}>Back to CatRep</Link>
                                </li>
                                <li><i className="icon ion-images"></i><div><a href={`/cat-catalog/${cat.id}/${cat.name}/photo`}>Images</a></div></li>
                                {catalogVideosEnabled && <li><i className="icon ion-ios-videocam"></i><div><a href={`/cat-catalog/${cat.id}/${cat.name}/video`}>Videos</a></div></li>}
                                <li><i className="icon ion-ios-videocam"></i><div><a href={`/cat-catalog/${cat.id}/${cat.name}/youtube`}>Youtube</a></div></li>
                            </ul>
                        </div>
                        {(!fileType || fileType === 'photo') &&
                            <Fragment>
                                <div className="col-md-2 static hidden-lg hidden-md">
                                    <AddPhotoButton cat={cat} current={current} />
                                </div>
                                <div className="col-md-7">
                                    <h4 className="grey">
                                        <i className="ion-ios-heart-outline icon-in-title"></i>CatAlog
                                    </h4>
                                    <div className="line"></div>
                                    <ul className="album-photos" id="cat-catalog-photos">
                                        <Masonry>
                                            {photos.map((photo, index) =>
                                                <li key={index}>
                                                    <div className="tile">
                                                        <a href="#/" data-toggle="modal" data-target={`.photo-${index}`}>
                                                            <img src={`${userStaticPath(cat.user)}/${photo.title}`} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className={`modal fade photo-${index}`} tabIndex="-1" role="dialog" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <img src={`${userStaticPath(cat.user)}/${photo.title}`} alt="" />
                                                                    {cat.user.id === current.id &&
                                                                        <div className="spacer-10">
                                                                            <MemberOnly hide={true}>
                                                                                <button className="btn-danger" onClick={() => handleDelete(photo.id)}>Delete</button>
                                                                            </MemberOnly>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                        </Masonry>
                                    </ul>
                                    {photos.length === 0 &&
                                        <div>
                                            <h4 className="grey">You didn't upload any photo yet.</h4>
                                            <h4>Please click Add Photo button to start adding new photos.</h4>
                                        </div>
                                    }
                                </div>
                                <div className="col-md-2 static hidden-sm hidden-xs">
                                    <AddPhotoButton cat={cat} current={current} />
                                </div>
                            </Fragment>
                        }
                        {catalogVideosEnabled && fileType === 'video' &&
                            <Fragment>
                                <div className="col-md-7">
                                    <ul className="album-videos">
                                        {videos.map((video, index) =>
                                            <li key={index}>
                                                <video key={index} controls>
                                                    <source src={`${userStaticPath(cat.user)}/${video.title}`} type="video/mp4" />
                                                    Your browser does not support HTML5 video.
                                                </video>
                                                {cat.user.id === current.id &&
                                                    <MemberOnly hide={true}>
                                                        <button className="btn-danger" onClick={() => handleDeleteVideo(video.id)}>Delete</button>
                                                    </MemberOnly>
                                                }
                                            </li>
                                        )}
                                    </ul>
                                    {videos.length === 0 &&
                                        <div>
                                            <h4 className="grey">You didn't upload any video yet.</h4>
                                            <h4>Please click Add Video button to start adding new photos.</h4>
                                        </div>
                                    }
                                </div>
                                <div className="col-md-2 static">
                                    {cat.user.id === current.id &&
                                        <MemberOnly>
                                            <button className="btn-primary" data-toggle="modal" data-target=".add-video-modal">Add Video</button>
                                        </MemberOnly>
                                    }
                                </div>
                            </Fragment>
                        }
                        {fileType === 'youtube' &&
                            <Fragment>
                                <div className="col-md-7">
                                    <ul className="album-videos">
                                        {youtubeUrls.map((video, index) =>
                                            <li key={index}>
                                                <iframe title="youtube" width="480" height="272" src={video.title.replace('watch?v=', 'embed/')} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                {cat.user.id === current.id &&
                                                    <MemberOnly hide={true}>
                                                        <button className="btn-danger" onClick={() => handleDeleteYoutube(video.id)}>Delete</button>
                                                    </MemberOnly>
                                                }
                                            </li>
                                        )}
                                    </ul>
                                    {youtubeUrls.length === 0 &&
                                        <div>
                                            <h4 className="grey">You didn't add any Youtube video yet.</h4>
                                            <h4>Please click Add Youtube button to add new video.</h4>
                                        </div>
                                    }
                                </div>
                                <div className="col-md-2 static">
                                    {cat.user.id === current.id &&
                                        <MemberOnly>
                                            <button className="btn-primary" data-toggle="modal" data-target=".add-youtube-modal">Add Youtube</button>
                                        </MemberOnly>
                                    }
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
        <PhotoModal
            modalClass="add-catalog-modal"
            onSave={onPhotoSave} />
        <VideoModal cat={cat} />
        <YoutubeModal cat={cat} />
    </Fragment>
)

export default Catalog;
