import React from 'react';

class Tooltip extends React.Component {
    setTooltip(type) {
        if (window.tooltip) {
            setTimeout(() => {
                window.tooltip(type);
            }, 1000);
        }
    }

    render() {
        this.setTooltip(this.props.type);
        return null;
    }
}

export default Tooltip;