import React, { useContext, useEffect, useRef, useState } from 'react';
import Chat from './Chat';
import { getCat } from '.././../../actions/cat';
import { getChats, getMessages, addMessage, getLastMessage, getNewMessages, readChat }
    from '.././../../actions/chat';
import { useParams } from 'react-router-dom';
import Context from '../../../context';
import PreLoader from '../../Common/PreLoader';
import EmptyChat from './EmptyChat';

function ChatContainer() {
    const { id } = useParams();
    const { current, setCurrent } = useContext(Context);
    const [cat, setCat] = useState();
    const [chat, setChat] = useState();
    const [chats, setChats] = useState([]);
    const [message, setMessage] = useState('');
    const [lastMessageDate, setLastMessageDate] = useState();
    const [messages, setMessages] = useState([]);
    const scrollRef = useRef();
    const inputRef = useRef();
    useEffect(() => {
        const interval = setInterval(getNewChatMessages, 3000);

        return () => {
            clearInterval(interval);
        }
    });

    useEffect(() => {
        (async () => {
            const fetchedCat = await getCat(id, current);
            setCat(fetchedCat);

            const fetchedChats = await getChats(id);
            fetchedChats.forEach(async (chat) => {
                chat.lastMessage = await getLastMessage(chat.id);
            });
            setChats(fetchedChats);
            if (fetchedChats.length > 0) {
                const firstChat = fetchedChats[0];
                setChat(firstChat);
                const fetchedMessages = await getMessages(firstChat.id);
                setChatMessages(fetchedMessages);
            }
        })();
    }, [id, current]);

    useEffect(() => {
        scrollToMyRef();
    });

    function handleChange(value) {
        setMessage(value.target.value);
    }

    function setChatMessages(messages) {
        if (messages.length <= 0) {
            return;
        }
        setMessages(messages);
        const date = new Date(messages[0].createdAt);
        setLastMessageDate(date.toISOString());
    }

    async function sendMessage() {
        if (!message) {
            return;
        }
        const addedMessage = await addMessage(chat.id, cat.id, message);
        const combinedMessages = [addedMessage, ...messages]
        setChatMessages(combinedMessages);
        setMessage('');
    }

    function scrollToMyRef() {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    async function handleKeyPress(target) {
        if (target.charCode === 13) {
            await sendMessage();
        }
    }

    async function getNewChatMessages() {
        if (!chat) {
            return;
        }

        handleReadChat();

        let newMessages = await getNewMessages(chat.id, lastMessageDate)
        if (newMessages.length <= 0) {
            return;
        }

        newMessages = newMessages.filter((newMessage) => !messages.some((oldMessage) => newMessage.id === oldMessage.id));

        const combinedMessages = [...newMessages.reverse(), ...messages];
        setChatMessages(combinedMessages);
    }

    if (!cat) {
        return <PreLoader />
    }

    if (chats.length <= 0 || chat == null) {
        return <EmptyChat cat={cat} />
    }

    function normalizeChats(chats) {
        return chats.map((chat) => {
            if (chat.fromCat.id === cat.id) {
                return {
                    ...chat,
                    cat: chat.toCat,
                    unreadCount: chat.fromUnreadCount
                };
            } else {
                return {
                    ...chat,
                    cat: chat.fromCat,
                    unreadCount: chat.toUnreadCount
                };
            }
        });
    }

    function normalizeMessages(messages) {
        return messages.map((message) => {
            if (message.catId === chat.fromCat.id) {
                message.cat = chat.fromCat;
                message.isLeft = true;
            } else if (message.catId === chat.toCat.id) {
                message.cat = chat.toCat;
            }

            return message;
        }).reverse();
    }

    async function handleSetChat(chatId) {
        const selectedChat = chats.find((item) => item.id === chatId);
        setChat(selectedChat);
        setChatMessagesAsRead(selectedChat);
        const fetchedMessages = await getMessages(selectedChat.id);
        setChatMessages(fetchedMessages);
    }

    async function handleReadChat() {
        await readChat(chat.id, id);

        setChatMessagesAsRead(chat);

        if (hasUnreadChat()) {
            setCurrent({
                ...current,
                unreadChats: current.unreadChats.filter((item) => item.id !== chat.id)
            });
        }
    }

    function hasUnreadChat() {
        return current.unreadChats.find((item) => item.id === chat.id) != null;
    }

    function setChatMessagesAsRead(chat) {
        setChats(chats.map((item) => {
            if (item.id === chat.id) {
                if (item.fromCat.id === cat.id) {
                    item.fromUnreadCount = 0;
                } else {
                    item.toUnreadCount = 0;
                }
            }
            return item;
        }));
    }

    return (
        <Chat
            cat={cat}
            activeChat={chat}
            chats={normalizeChats(chats)}
            messages={normalizeMessages(messages)}
            handleChange={handleChange}
            sendMessage={sendMessage}
            inputText={message}
            scrollRef={scrollRef}
            inputRef={inputRef}
            setChat={handleSetChat}
            handleKeyPress={handleKeyPress} />
    );
}

export default ChatContainer;
