import React, { useContext, useEffect, useState } from 'react';
import FriendList from './FriendList';
import { getUser, getFriends } from '../../../actions/user';
import { useParams } from 'react-router-dom';
import PreLoader from '../../Common/PreLoader';
import Context from '../../../context';

function FriendListContainer() {
    const { id } = useParams();
    const [user, setUser] = useState();
    const [friends, setFriends] = useState([]);
    const { current } = useContext(Context);

    useEffect(() => {
        (async () => {
            const fetchedUser = await getUser(id, current);
            setUser(fetchedUser);
            const fetchedFriends = await getFriends(id);
            setFriends(fetchedFriends);
        })();
    }, [id, current]);

    if (!user) {
        return <PreLoader />
    }

    return (
        <FriendList
            user={user}
            friends={friends} />
    )
}

export default FriendListContainer;
