import React from 'react';

const Validation = ({ validationErrors }) => {
    let errorCount = 0;
    const validationResult = validationErrors && Object.keys(validationErrors).map(key => {
        if (++errorCount <= 3) {
            return <p key={key} className="validation-error">{validationErrors[key]}</p>
        } else {
            return null;
        }
    });

    return (
        <>
            {validationResult || null}
        </>
    )
}

export default Validation;