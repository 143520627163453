import React from 'react';
import UserTimeline from '../../Common/UserTimeline';
import CatModal from '../../Common/CatModal';
import SettingsModal from '../SettingsModal';
import PasswordModal from '../PasswordModal';
import { userStaticPath } from '../../../utils/photo';
import { ofUser } from '../../../utils/string';
import Masonry from '../../Common/Masonry';
import MemberOnly from '../../Common/MemberOnly';
import PhotoModal from '../../Common/PhotoModal';

const Information = ({ user, favorites, handleDelete, handleFavoriteSave }) =>
    <>
        <div className="main container">
            <div className="timeline">
                <UserTimeline user={user} activeMenu="about" />
                <div id="page-contents">
                    <div className="row">
                        <div className="col-md-3">
                            <ul id="usermenu-list" className="edit-menu">
                                {user.isMe &&
                                    <>
                                        <li className="active">
                                            <i className="icon ion-android-image"></i>
                                            <MemberOnly>
                                                <a href="#/" data-toggle="modal" data-target=".add-favorite-modal">Add Favorite Photo(s)</a>
                                            </MemberOnly>
                                        </li>
                                        <li>
                                            <i className="icon ion-ios-information-outline"></i>
                                            <MemberOnly>
                                                <a href="#/" data-toggle="modal" data-target=".settings-modal">CatRep Settings</a>
                                            </MemberOnly>
                                        </li>
                                        <li>
                                            <i className="icon ion-ios-locked-outline"></i>
                                            <MemberOnly>
                                                <a href="#/" data-toggle="modal" data-target=".password-modal">Change Password</a>
                                            </MemberOnly>
                                        </li>
                                        <SettingsModal />
                                        <PasswordModal />
                                    </>
                                }
                            </ul>
                        </div>
                        <div className="col-md-7">
                            <h4 className="grey">
                                <i className="ion-ios-heart-outline icon-in-title"></i>
                                {`${user.isMe ? 'My' : ofUser(user)} Favorites`}
                            </h4>
                            <div className="line"></div>
                            <ul className="album-photos">
                                <Masonry>
                                    {favorites.map((photo, index) =>
                                        <li key={index}>
                                            <div className="tile">
                                                <a href="#/" data-toggle="modal" data-target={`.photo-${index}`}>
                                                    <img src={`${userStaticPath(user)}/${photo.title}`} alt="" />
                                                </a>
                                            </div>
                                            <div className={`modal fade photo-${index}`} tabIndex="-1" role="dialog" aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <MemberOnly hide={true}>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                            </MemberOnly>
                                                        </div>
                                                        <div className="modal-body">
                                                            <img src={`${userStaticPath(user)}/${photo.title}`} alt="" />
                                                            {user.isMe &&
                                                                <div className="spacer-10">
                                                                    <MemberOnly hide={true}>
                                                                        <button className="btn-danger" onClick={() => handleDelete(photo.id)}>Delete</button>
                                                                    </MemberOnly>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </Masonry>
                            </ul>
                        </div>
                        <div className="col-md-2 align-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CatModal />
        <PhotoModal
            onSave={handleFavoriteSave}
            modalClass="add-favorite-modal" />
    </>

export default Information;
