import React from 'react';
import Validation from '../../Common/Validation';

const ChangePassword = ({ handleChange, onChangePasswordClick, validationErrors, onTabChange }) =>
    <div className={`tab-pane active`} id="ChangePassword">
        <h3>Change Password</h3>
        <p className="text-muted">Change Your Password</p>
        <form name="ChangePassword_form" id='ChangePassword_form'>
            <div className="row">
                <div className="form-group col-xs-12">
                    <label className="sr-only">Password</label>
                    <input onChange={value => handleChange('password', value)} className="form-control input-group-lg" type="password" name="password" title="Enter new password" placeholder="New Password" autoComplete="off" />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-xs-12">
                    <label className="sr-only">Password</label>
                    <input onChange={value => handleChange('password2', value)} className="form-control input-group-lg" type="password" name="password" title="Enter password again" placeholder="New Password Again" autoComplete="off" />
                </div>
            </div>
        </form>
        <Validation validationErrors={validationErrors} />
        <p><a href="/">Click to come back to login page</a></p>
        <button className="btn btn-primary margin-right-20" onClick={onChangePasswordClick}>Change Password</button>
    </div>
export default ChangePassword;                            