import React, { useCallback, useContext, useEffect, useState } from 'react';
import Blog from './Blog';
import { getCat, getBlogs, addBlog } from '../../../actions/cat';
import { createContent } from '../../../actions/feed';
import Scrollable from '../../Common/Scrollable';
import { useParams } from 'react-router-dom';
import Context from '../../../context';
import PreLoader from '../../Common/PreLoader';

function BlogContainer() {
    const { id } = useParams();
    const { current } = useContext(Context);
    const [cat, setCat] = useState();
    const [blogs, setBlogs] = useState([]);
    const [model, setModel] = useState({});

    const loadDataCallback = useCallback(async () => {
        const fetchedCat = await getCat(id, current);
        setCat(fetchedCat);
        const fetchedBlogs = await getBlogs(id);
        setBlogs(fetchedBlogs);
    }, [id, current]);

    useEffect(() => {
        (async () => {
            await loadDataCallback();
        })();
    }, [loadDataCallback]);

    function onPhotoSave(photo) {
        setModel({
            ...model,
            photo: photo
        });
    }

    function onInputChange(field, value) {
        setModel({
            ...model,
            [field]: value.target.value
        });
    }

    async function onPublish() {
        const content = model.message ? await createContent(model.message) : '';
        const createdBlog = await addBlog(cat.id, content, model.photo);
        setBlogs([
            createdBlog,
            ...blogs
        ]);
        setModel({
            message: '',
            photo: null
        });
    }

    async function getNewBlogs(index) {
        const newBlogs = await getBlogs(id, index);
        setBlogs(blogs => [
            ...blogs,
            ...newBlogs
        ]);
    }

    function onEdit(blog) {
        setBlogs(blogs.map((item) => item.id === blog.id ? blog : item));
    }

    function onDelete(blog) {
        setBlogs(blogs.filter((item) => item.id !== blog.id));
    }

    if (!cat) {
        return <PreLoader />;;
    }

    return (
        <Scrollable count={blogs.length} callback={getNewBlogs}>
            <Blog
                cat={cat}
                blogs={blogs}
                onPhotoSave={onPhotoSave}
                onInputChange={onInputChange}
                onPublish={onPublish}
                message={model.message}
                onEdit={onEdit}
                onDelete={onDelete} />
        </Scrollable>
    )
}

export default BlogContainer;
