import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import timeAgo from '../../../utils/timeAgo';
import { userStaticPath } from '../../../utils/photo';
import { userProfile } from '../../../utils/photo';
import { userLink, isDefaultUser } from '../../../utils/link';
import { feedType } from '../../../utils/enum';
import PostContextMenu from './PostContextMenu';
import CommentContextMenu from './CommentContextMenu';
import MemberOnly from '../MemberOnly';

const PostContent = ({ current, feed, onLike, onAddComment, onInputChange, commentMessage, onEdit, onDelete }) => {
    const content = JSON.parse(feed.content);
    const isFeedMine = isDefaultUser(current) || feed.user.id === current.id;
    const isCommentMine = (comment) => isDefaultUser(current) || comment.user.id === current.id;
    return (
        <div key={feed.id} className="post-content">
            {feed.type === feedType.blog &&
                <div className="post-date hidden-xs hidden-sm">
                    <h5>{feed.profile.name}</h5>
                    <p className="text-grey">{timeAgo(feed.createdAt)}</p>
                </div>
            }
            <div className="post-container">
                <img src={feed.profile.profilePhoto} alt="user" className="profile-photo-md pull-left" />
                {isFeedMine && <PostContextMenu feed={feed} onEdit={onEdit} onDelete={onDelete} />}
                <div className="post-detail">
                    <div className="user-info">
                        <h5>
                            <Link to={feed.profile.profileLink} className="profile-link">
                                {feed.profile.name}
                            </Link>
                            {!isFeedMine && <span className="following">{feed.profile.isCat ? 'CatMate' : 'CatRep'}</span>}
                        </h5>
                        <p className="text-muted">{timeAgo(feed.createdAt)}</p>
                    </div>
                    <div className="reaction">
                        <MemberOnly>
                            <a onClick={() => onLike(feed.id)} href="#/" className="btn text-green">
                                <img src="/images/thumbsup.png" alt="" />{feed.likes > 0 ? feed.likes : null}</a>
                        </MemberOnly>
                    </div>
                    <div className="line-divider"></div>
                    <div className="post-text">
                        {feed.type === feedType.photo &&
                            <Fragment>
                                <p>{feed.profile.name} added a photo</p>
                                <img src={`${userStaticPath(feed.user)}/${content.file}`} alt="post" className="img-responsive post-image" />
                            </Fragment>
                        }
                        {feed.type === feedType.video &&
                            <Fragment>
                                <p>{feed.profile.name} added a video</p>
                                <video controls>
                                    <source src={`${userStaticPath(feed.user)}/${content.file}`} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                </video>
                            </Fragment>
                        }
                        {feed.type === feedType.youtube &&
                            <Fragment>
                                <p>{feed.profile.name} added a Youtube video</p>
                                <iframe width="480" title="youtube" height="272" src={content.file.replace('watch?v=', 'embed/')} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Fragment>
                        }
                        {content.message &&
                            <p>{content.message.replace(content.link, '')}</p>
                        }
                        {content.title &&
                            <a href={content.url} target="_blank" rel="noopener noreferrer"><h4>{content.title}</h4></a>
                        }
                        {content.description &&
                            <p>{content.description}</p>
                        }
                        {content.image &&
                            <a href={content.url} target="_blank" rel="noopener noreferrer">
                                <img src={content.image} alt="post" className="img-responsive post-image" />
                            </a>
                        }
                        {feed.type !== feedType.photo && feed.files.map((photo) =>
                            <img key={photo.id} src={`${userStaticPath(feed.user)}/${photo.title}`} alt="" className="img-responsive post-image" />
                        )}
                    </div>
                    {feed.comments.length > 0 &&
                        <Fragment>
                            <div className="line-divider"></div>
                            <div className="comments-wrapper">
                                {feed.comments.filter((comment) => comment.content).map((comment) => {
                                    const commentContent = JSON.parse(comment.content);
                                    return (
                                        <div key={comment.id} className="post-comment">
                                            <img src={userProfile(comment.user)} alt="" className="profile-photo-sm" />
                                            {isCommentMine(comment) && <CommentContextMenu feed={feed} comment={comment} onEdit={onEdit} onDelete={onDelete} />}
                                            <p>
                                                <Link to={userLink(comment.user)} className="profile-link">
                                                    {comment.user.firstname} {comment.user.lastname}
                                                </Link>
                                                {commentContent.message}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        </Fragment>
                    }
                    {feed.type !== feedType.blog &&
                        <MemberOnly hide="true">
                            <div className="line-divider"></div>
                            <div className="post-add-comment">
                                <div className="row">
                                    <div className="col-md-8 col-sm-8 col-xs-12 add-comment">
                                        <img src={userProfile(current)} alt="" className="profile-photo-sm" />
                                        <input onChange={onInputChange} value={commentMessage} type="text" className="form-control" placeholder="Post a comment" />
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12 align-center">
                                        <button onClick={() => onAddComment(feed.id)} className="btn btn-primary">Add Comment</button>
                                    </div>
                                </div>
                            </div>
                        </MemberOnly>
                    }
                </div>
            </div>
        </div>
    )
}

export default PostContent;
