import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUser } from '../../../actions/user';
import Context from '../../../context';
import PreLoader from '../../Common/PreLoader';
import Catchies from './Catchies';

function CatchiesContainer() {
    const { id } = useParams();
    const { current } = useContext(Context);
    const [user, setUser] = useState();

    useEffect(() => {
        (async () => {
            const fetchedUser = await getUser(id, current);
            setUser(fetchedUser);
        })();
    }, [id, current])

    if (!user) {
        return <PreLoader />
    }

    return (
        <Catchies
            user={user} />
    )
}

export default CatchiesContainer;