import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Guide = () =>
    <>
        <div id="lp-register">
            <div className="wrap">
                <div className="main">
                    <Header />
                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="intro-texts">
                                </div>
                            </div>
                            <div className="col-sm-6 col-sm-offset-1">
                                <div className="panel-group" id="accordion">
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">CatRep User Guide</a>
                                            </h4>
                                        </div>
                                        <div id="collapse2" className="panel-collapse collapse in">
                                            <div className="panel-body">
                                                <div id="Translation">
                                                    <strong>CatRep</strong><br />All users of this platform are cat representatives and defined as CatReps.&nbsp; CatReps are the operators of the cat accounts.<br />Any cat lover who currently has or previously had cat(s) can open a CatRep account.&nbsp; A CatRep can have multiple cat accounts.&nbsp; All active cat accounts will be saved under <strong>My Cats</strong>.<br />Cat lovers who would like to be part of this network whose cat(s) passed away and have no longer have a cat can also open a CatRep account and create cat account(s) under <strong>Catchies</strong>.
                                                    <br /><strong><span>Features that are available for the CatReps</span></strong>
                                                    <br /><strong>My Favorites</strong><br /> Here CatReps can share their favorite photographs of their cats.<br /><strong><span>My Cats<br /></span></strong>All active cat accounts of each CatRep will be listed here.<br /><strong><span>Catchies (Memorable Ones)<br /></span></strong>Photographs and or videos of cats that passed away will be shared here.&nbsp; It is possible to open an account for cat(s) that passed away without having to have other cat account(s).&nbsp; If that&rsquo;s the case CatRep clicks on &ldquo;Yes&rdquo; under the if passed away option while opening a cat account.<br />In the case of an already registered cat&rsquo;s loss, the CatRep clicks on &ldquo;Edit Cat&rdquo; in the related cat&rsquo;s account, then clicks on &ldquo;Yes&rdquo; option under &ldquo;If passed away&rdquo;, and also enters the date of death of the related cat.&nbsp; Following that all the data (including under <strong>CatChat</strong> and <strong>CatBlog</strong>) belonging to the related cat will be automatically saved under Catchies.<br /><strong><span>CatReps</span></strong>
                                                    <br />Other CatReps (users) on this platform with whom a CatRep is connected.
                                                    For CatReps to be connected, one has to send a &ldquo;Connect&rdquo; request to another, and it should be accepted by the receiver.&nbsp; When a request is sent, it will appear on the receiver&rsquo;s home page.&nbsp; At the same time the &ldquo;Connect&rdquo; button under <strong>New CatReps</strong> will change into &ldquo;Requested&rdquo; on the sender&rsquo;s account, and into &ldquo;Approve&rdquo; on the receiver&rsquo;s account.&nbsp; If the receiver approves, the two CatReps will be connected.<br /><strong><span>Add Cat<br /></span></strong>Via this button CatReps can create multiple cat accounts.&nbsp; All active cat accounts created will be saved under <strong>My Cats</strong>.&nbsp; Accounts of cats&nbsp; that have passed away will be saved under <strong>Catchies</strong>.<br />Add Cat is also the section where <strong>CatID</strong> is created. <br /><strong><span>CatShare</span></strong><span><br /></span>This is the only feature allowing CatReps to communicate directly with each other to share other cat related videos, photographs, articles, etc.<br /><strong><span>New CatReps<br /></span></strong>New users (CatReps) will be listed under New CatReps.<br />In this section CatReps can filter to see the &ldquo;Connect&rdquo; request that they have sent or received.<br />When one CatRep sends a &ldquo;Connect&rdquo; request to another, the receiver will see a notification mark next to the New CatReps title.<br /><strong><span>CatID</span></strong>
                                                    <br />The name, date of birth, gender, place of birth and breed of each cat will be shown here.&nbsp; For cats under <strong>Catchies</strong> the date of death will be shown as well.&nbsp; <span>Data fields with an asterisk (*) next to them are mandatory.&nbsp; Other data fields such as date of birth are optional.<br /></span><strong><span>CatAlog <br /></span></strong>Here CatReps can create an album / documentary for their cat accounts by uploading their photographs and videos.&nbsp; There will be a temporary limitation to direct video upload until the site grows, meanwhile there will be limitless upload possibility still under CatAlog via youtube.
                                                    <br /><strong><span>CatBlog<br /></span></strong>Cats&rsquo; daily life experience and notes through the eyes of their Cat Reps.&nbsp; CatReps are given the option to make any of their cat&rsquo;s diaries visible to either only those with whom they are connected or to everyone or to nobody.
                                                    <br /><strong><span>CatChat<br /></span></strong>Here CatMates (via the CatReps) can chat with each other.&nbsp; This feature allows multiple CatMates to chat at the same time.&nbsp; If two CatReps are not connected cats under their accounts can not be CatMates, therefore can not chat with each other. CatChat can be started directly through the CatChat section by clicking on the &ldquo;Start CatChat&rdquo; button, or through the <strong>CatMates</strong> section, after selecting a CatMate, via clicking on&nbsp; the &ldquo;Send Message&rdquo; button.
                                                    <br /><strong><span>CatMates</span></strong><br />When two CatReps are connected all cats under their account will automatically be connected and become friends / CatMates except for the cats under <strong>Catchies</strong>.&nbsp; Each cat&rsquo;s friends will be listed under the CatMates title.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>

export default Guide;