import React, { useContext, useEffect, useState } from 'react';
import CatChoose from './CatChoose';
import { addChat } from '../../../actions/chat';
import { getFriends } from '../../../actions/cat';
import { useHistory } from 'react-router-dom';
import Context from '../.././../context';

function CatChooseContainer(props) {
    const history = useHistory();
    const { current } = useContext(Context);
    const [cats, setCats] = useState([]);
    const [areMyFriends, setAreMyFriends] = useState(false);

    useEffect(() => {
        (async () => {
            const friendCats = await getFriends(props.cat.id);
            if (props.cat.isMine) {
                setCats(friendCats.filter((cat) => !cat.deathdate));
                setAreMyFriends(true);
            } else {
                setCats(current.cats.filter((cat) => !cat.deathdate));
            }
        })();

    }, [props.cat, current]);

    async function onChoose(fromCatId) {
        if (!props.cat.isMine) {
            await addChat(fromCatId, props.cat.id);
            history.push(`/cat-chat/${fromCatId}`);
        } else {
            await addChat(props.cat.id, fromCatId);
            history.push(`/cat-chat/${props.cat.id}`);
        }
        window.hideModal();
        window.location.reload();
    }

    return (
        <CatChoose
            cats={cats}
            onChoose={onChoose}
            areMyFriends={areMyFriends} />
    )
}

export default CatChooseContainer;
