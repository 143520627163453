import React from 'react';

class Scrollable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            index: 0,
            count: 0,
            history: []
        }
    }

    componentDidMount() {
        if (this.props.element) {
            this.props.element.current.addEventListener('scroll', this.onScroll, false);
        } else {
            window.addEventListener('scroll', this.onScroll, false);
        }
    }

    componentWillUnmount() {
        if (this.props.element) {
            this.props.element.current.removeEventListener('scroll', this.onScroll, false);
        } else {
            window.removeEventListener('scroll', this.onScroll, false);
        }
    }

    onScroll = () => {
        if (!this.isUnique(this.state.history)) {
            return;
        }

        if (this.isEndOfScroll()) {
            this.setState({
                index: this.state.index + 1,
                history: this.state.history.concat(this.props.count)
            }, () => {
                if (this.isUnique(this.state.history) && this.isFunction(this.props.callback)) {
                    this.props.callback(this.state.index);
                }
            });
        }
    }

    isEndOfScroll() {
        if (this.props.element) {
            const element = this.props.element.current;
            if (((element.scrollTop + element.clientHeight) / element.scrollHeight) < 0.6) {
                return true;
            }
        } else {
            const scrollTop = Math.max(window.document.body.scrollTop, window.document.documentElement.scrollTop);
            if (scrollTop + window.innerHeight >= window.document.body.scrollHeight) {
                return true;
            }
        }

        return false;
    }

    isUnique(myArray) {
        if (this.props.element) {
            return true;
        }
        return myArray.length === new Set(myArray).size;
    }

    isFunction(functionToCheck) {
        const functionName = functionToCheck && {}.toString.call(functionToCheck);
        return ['[object Function]', '[object AsyncFunction]'].includes(functionName);
    }

    render() {
        return <>
            {this.props.children}
        </>
    }
}

export default Scrollable;