import React, { useCallback, useEffect, useState } from 'react';
import { ruleRunner, run } from '../../../../utils/validation';
import { required } from '../../../../utils/validation/rules';
import { createContent, editFeed } from '../../../../actions/feed';
import EditFeed from './EditFeed';

function EditFeedContainer(props) {
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState();

    const setFeedCallback = useCallback(() => {
        const content = JSON.parse(props.feed.content);
        setMessage(content.message);
    }, [props.feed]);

    useEffect(() => {
        setFeedCallback();
    }, [setFeedCallback]);

    function onInputChange(field, value) {
        setMessage(value.target.value);
    }

    async function onSave() {
        const newValidationErrors = run({ message }, fieldValidations);
        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        } else {
            setValidationErrors(null);
            const content = await createContent(message);
            const feed = {
                ...props.feed,
                content: JSON.stringify(content)
            };

            const editedFeed = await editFeed(feed);
            window.hideModal();
            props.onEdit(editedFeed);
        }
    }

    return (
        <EditFeed
            feed={props.feed}
            onInputChange={onInputChange}
            message={message}
            validationErrors={validationErrors}
            onSave={onSave} />
    )
}

const fieldValidations = [
    ruleRunner("message", "Share message", required),
];

export default EditFeedContainer;
