import React from 'react';
import { userProfile } from '../../utils/photo';
import { userLink } from '../../utils/link';
import { Link } from 'react-router-dom';
import { ofCat } from '../../utils/string';

const UserCard = ({ user, cat }) =>
    <div className="suggestions" id="sticky-sidebar">
        <h4 className="grey">CatRep</h4>
        <div className="follow-user">
            <img src={userProfile(user)} alt="" className="profile-photo-sm pull-left" />
            <div>
                <h5>{user.firstname} {user.lastname}</h5>
                <Link className="text-green" to={userLink(user)}>Back to {ofCat(cat)} CatRep</Link>
            </div>
        </div>
    </div>

export default UserCard;