import React from 'react';

const TermsAndConditions = () =>
    <>
        <p>Last
updated: April 23, 2019</p>
        <p>Please
        read these Terms and Conditions (&quot;Terms&quot;, &quot;Terms and
        Conditions&quot;) carefully before using the www.whiskersite.com
        website (the &quot;Service&quot;) operated by Whiskersite (&quot;us&quot;,
&quot;we&quot;, or &quot;our&quot;).</p>
        <p>Your
        access to and use of the Service is conditioned on your acceptance of
        and compliance with these Terms. These Terms apply to all visitors,
users and others who access or use the Service.</p>
        <p>
            By accessing or using the Service you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
access the Service.</p>
        <p>

            <b>Accounts</b></p>
        <p>When
        you create an account with us, you must provide us information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
termination of your account on our Service.</p>
        <p>You
        are responsible for safeguarding the password that you use to access
        the Service and for any activities or actions under your password,
whether your password is with our Service or a third-party service.</p>
        <p>You
        agree not to disclose your password to any third party. You must
        notify us immediately upon becoming aware of any breach of security
or unauthorized use of your account.</p>
        <p><b>Links To Other Web Sites</b></p>
        <p>Our
        Service may contain links to third-party web sites or services that
are not owned or controlled by Whiskersite.</p>
        <p>Whiskersite
        has no control over, and assumes no responsibility for, the content,
        privacy policies, or practices of any third party web sites or
        services. You further acknowledge and agree that Whiskersite shall
        not be responsible or liable, directly or indirectly, for any damage
        or loss caused or alleged to be caused by or in connection with use
        of or reliance on any such content, goods or services available on or
through any such web sites or services.</p>
        <p>We
        strongly advise you to read the terms and conditions and privacy
policies of any third-party web sites or services that you visit.</p>
        <p>
            <b>Termination</b></p>
        <p>We
        may terminate or suspend access to our Service immediately, without
        prior notice or liability, for any reason whatsoever, including
without limitation if you breach the Terms.</p>
        <p>All
        provisions of the Terms which by their nature should survive
        termination shall survive termination, including, without limitation,
        ownership provisions, warranty disclaimers, indemnity and limitations
of liability.</p>
        <p>We
        may terminate or suspend your account immediately, without prior
        notice or liability, for any reason whatsoever, including without
limitation if you breach the Terms.</p>
        <p>Upon
        termination, your right to use the Service will immediately cease. If
        you wish to terminate your account, you may simply discontinue using
the Service.</p>
        <p>All
        provisions of the Terms which by their nature should survive
        termination shall survive termination, including, without limitation,
        ownership provisions, warranty disclaimers, indemnity and limitations
of liability.</p>
        <p>
            <b>Governing Law</b></p>
        <p>These
        Terms shall be governed and construed in accordance with the laws of
Netherlands, without regard to its conflict of law provisions.</p>
        <p>Our
        failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms
        is held to be invalid or unenforceable by a court, the remaining
        provisions of these Terms will remain in effect. These Terms
        constitute the entire agreement between us regarding our Service, and
        supersede and replace any prior agreements we might have between us
regarding the Service.</p>
        <p>
            <b>Changes</b></p>
        <p>We
        reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide
        at least 30 days notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole
discretion.</p>
        <p>By
        continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not
agree to the new terms, please stop using the Service.</p>
        <br />
        <p><b>Additional
Terms and Conditions</b></p>
        <p><b>Safety</b></p>
        <p>To
        keep Whiskersite platform safe users must adhere to the following
commitments</p>
        <p>1.
        You will not send or otherwise post unauthorized commercial
communications (such as spam) on Whiskersite.</p>
        <p>2.
        You will not collect users' content or information, or otherwise
        access Whiskersite, using automated means (such as harvesting bots,
robots, spiders, or scrapers).</p>
        <p>3.
You will not upload viruses or other malicious code.</p>
        <p>4.
        You will not solicit login information or access an account belonging
to someone else, without their prior written consent.</p>
        <p>5.
You will not bully, intimidate, or harass any user.</p>
        <p>6.
        You will not post content that: is hateful, threatening, or
        pornographic; incites violence; or contains nudity or graphic or
gratuitous violence.</p>
        <p>7.
        You will not offer any contest, giveaway, or any other promotion on
Whiskersite without our prior written consent.<br />
            8. You will not
            use Whiskersite to do anything unlawful, misleading, malicious, or
discriminatory.</p>
        <p>9.
        You will not do anything that could disable, overburden, or impair
        the proper working of Whiskersite, such as a denial of service attack
        of implant worms, defects, Trojan horses or other items of a
destructive nature.</p>
        <p>10.
        You will not facilitate or encourage any violations of this
Statement.</p>
        <p><b><br />
            Account
Security</b></p>
        <p>1.
        You will not provide any false personal information on registration,
        or create an account for anyone other than yourself without
        permission. You must provide true, accurate and complete registration
        information. You must be 13 years (with the consent of the parent(s)
        until age of 18) or older to use the Whiskersite.com service. By
        registering for the Whiskersite.com service, you represent and
        warrant that you are 13  or older and that you have the capacity to
understand, agree to and comply with this Statement.</p>
        <p>2.
        You will create a profile for a cat that you take care of and you
        certify that you have the right to distribute photos of this cat and
        that they do not violate any copyright laws. You will not create a
fictional cat account of a nonexistent cat.</p>
        <p>3.
        You can only create a cat profile to Whiskersite, no other pet types
are allowed.</p>
        <p>4.
        You will not create more than one personal profile and not more than
one account for each of your cats.</p>
        <p>5.
        If we disable your account, you will not create another account
without our permission.</p>
        <p>6.
You will not use your personal profile for commercial gain.</p>
        <p>7.
You will keep your contact information accurate and up-to-date.</p>
        <p>8.
        You will not share your password, let anyone else access your
        account, or do anything else that might jeopardize the security of
your account.</p>
        <p>9.
        You will not transfer your account to anyone without first obtaining
our written permission.</p>
        <p>10.
        If you select a username for your account we reserve the right to
remove or reclaim it if we believe appropriate.<br />
            <br />
            <b>Sharing
Content and Information</b></p>
        <p>1.
        For content that is covered by intellectual property rights, like
        photos, you specifically give us the following permission: you grant
        us a non-exclusive, transferable, sub-licensable, royalty-free,
        worldwide license to use any content that you post on or in
connection with Whiskersite.</p>
        <p>2.
        When you delete your content, you need to understand that removed
content may persist in backup copies for a reasonable period of time.</p>
        <p>3.
        When you publish content it means that you are allowing everyone,
        including people off of Whiskersite, to access and use that
information, and to associate it with you.<br />
            <br />
            <b>Respect and
People's Rights</b></p>
        <p>1.
        You will not post content or take any action on Whiskersite that
        infringes or violates someone else's rights or otherwise violates the
law.</p>
        <p>2.
        We may remove any content or information you post on Whiskersite if
we believe that it violates this Statement.</p>
        <p>3.
        If we remove your content for infringing someone else's copyright,
        and you believe we removed it by mistake, we will provide you with an
opportunity to appeal.</p>
        <p>4.
        If you repeatedly infringe other people's intellectual property
rights, we will disable your account when appropriate.</p>
        <p>5.
        You will not use our copyrights or trademarks (including Whiskersite
        logo) or any confusingly similar marks, without our written
permission.</p>
        <p>6.
        You will not post private information and private e-mail and chat
conversation on Whiskersite.<br />
            <br />
            <b>Copyright Policy</b></p>
        <p>Whiskersite.com
        respects the intellectual property rights of others. You may not
        post, modify, distribute, or reproduce in any way any copyrighted
        material, trademarks, or other proprietary information belonging to
        others without obtaining the prior written consent of the owner of
such proprietary rights.</p>
        <p>It
        is our policy to respond to notices of alleged infringement that
        comply with the Digital Millennium Copyright Act. If you believe your
        work has been copied in a way that constitutes copyright infringement
        or are aware of any infringing material on the Web site, please
        contact us and provide us with the following information: an
        electronic or physical signature of the person authorized to act on
        behalf of the owner of the copyright interest; a description of the
        copyrighted work that you claim has been infringed; a description of
        where the material that you claim is infringing is located on the
        Website (including a URL and/or screen shot); your address, telephone
        number, and email address; a written statement by you that you have a
        good faith belief that the disputed use is not authorized by the
        copyright owner, its agent, or the law; a statement by you, made
        under penalty of perjury, that the above information in your notice
        is accurate and that you are the copyright owner or authorized to act
on the copyright owner's behalf.<br />
            <br />
            <b>Termination</b></p>
        <p>If
        you violate the letter or spirit of this Statement, or otherwise
        create risk or possible legal exposure for us, we can stop providing
        all or part of Whiskersite to you. Whiskersite reserves the right to
        terminate your Whiskersite membership and terminate your access to
        the Service for violation of the Whiskersite’s Terms and
        Conditions. In case of your Whiskersite membership and subscription
        to the Service be terminated, all fees and charges (if any) are
nonrefundable.<br />
            <br />
            <b>Disputes</b></p>
        <p>If
        anyone brings a claim against us related to your actions, content or
        information on Whiskersite, you will indemnify and hold us harmless
        from and against all damages, losses, and expenses of any kind
        (including reasonable legal fees and costs) related to such
claim.<br />
            <br />
            <b>Disclaimer of Warranties</b></p>
        <p>The
        Whiskersite.com Service, and all materials, information (including,
        without limitation, any information or materials obtained or accessed
        through the Whiskersite.com Services), products and services included
        therein are provided &quot;as is,&quot; with no warranties
        whatsoever. Whiskersite.com expressly disclaims to the fullest extent
        permitted by law all express, implied, and statutory warranties,
        including, without limitation, the warranties of merchantability,
        fitness for a particular purpose, and non- infringement of
proprietary rights.<br />
            <br />
            You agree that your use of the
Whiskersite.com Service is entirely at your own risk.<br />
            <br />
            <b>Limitation
of Liability</b></p>
        <p>Under
        no circumstances will Whiskersite.com be liable to you for any
        indirect, incidental, consequential, special or exemplary damages
        arising out of or in connection with use of the Whiskersite.com
        Service, whether or not Whiskersite.com has been advised of the
        possibility of such damages, such limitation of liability shall apply
        (I) whether the damages arise from use or misuse of and reliance on
        the Whiskersite.com Service, from inability to use the
        Whiskersite.com Service, or from the interruption, suspension, or
        termination of the Whiskersite.com Service (including such damages
        incurred by third parties), and (II) notwithstanding any failure of
        essential purpose of any limited remedy and to the fullest extent
        permitted by law. Some jurisdictions do not allow the exclusion or
        limitation of incidental or consequential damages, so the above
limitation and exclusions may not apply to you. <b>Under no circumstances shall Whiskersite.com be liable to you for any
amount</b>.<br />
            <br />
            We reserve the right to modify or terminate
            the Whiskersite.com service for any reason, and without notice,
            without liability to you, any other member or any third party. We
            also reserve the right to modify terms of this Statement without
            notice. You are responsible for regularly reviewing this Statement so
that you will be apprised of any changes.<br />
            <br />
            <b>Contact Us</b></p>
        <p>If
you have any questions about these Terms, please contact us at <a href="https://www.whiskersite.com/contact">https://www.whiskersite.com/contact</a>.</p>
    </>

export default TermsAndConditions;