import React from 'react';
import Validation from '../../Common/Validation';

const Register = ({ countries, handleChange, onRegisterClick, validationErrors, activeTab, onTabChange }) =>
    <div className={`tab-pane ${activeTab === 'register' ? 'active' : ''}`} id="register">
        <h3>Create CatRep Account</h3>
        <p className="text-muted">All users of this platform wil be cat representatives and defined as CatReps</p>
        <form name="registration_form" id='registration_form' className="form-inline" onSubmit={onRegisterClick}>
            <div className="row">
                <div className="form-group col-xs-6">
                    <label htmlFor="firstname" className="sr-only">First Name</label>
                    <input id="firstname" onChange={value => handleChange('firstname', value)} className="form-control input-group-lg" type="text" name="firstname" title="Enter first name" placeholder="First name" />
                </div>
                <div className="form-group col-xs-6">
                    <label htmlFor="lastname" className="sr-only">Last Name</label>
                    <input id="lastname" onChange={value => handleChange('lastname', value)} className="form-control input-group-lg" type="text" name="lastname" title="Enter last name" placeholder="Last name" />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-xs-12">
                    <label htmlFor="email" className="sr-only">Email</label>
                    <input id="email" onChange={value => handleChange('email', value)} className="form-control input-group-lg" type="text" name="Email" title="Enter Email" placeholder="Your Email" autoComplete="off" />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-xs-12">
                    <label htmlFor="password" className="sr-only">Password</label>
                    <input id="password" onChange={value => handleChange('password', value)} className="form-control input-group-lg" type="password" name="password" title="Enter password" placeholder="Password" autoComplete="off" />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-xs-12">
                    <label htmlFor="password2" className="sr-only">Password Again</label>
                    <input id="password2" onChange={value => handleChange('password2', value)} className="form-control input-group-lg" type="password" name="password2" title="Enter password again" placeholder="Enter Password Again" autoComplete="off" />
                </div>
            </div>
            <div className="row">
                <p className="birth"><strong>Date of Birth</strong></p>
                <div className="form-group col-sm-3 col-xs-3">
                    <label htmlFor="month" className="sr-only"></label>
                    <select className="form-control" id="day" onChange={value => handleChange('day', value)}>
                        <option value="Day">Day</option>
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                        <option>06</option>
                        <option>07</option>
                        <option>08</option>
                        <option>09</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                        <option>24</option>
                        <option>25</option>
                        <option>26</option>
                        <option>27</option>
                        <option>28</option>
                        <option>29</option>
                        <option>30</option>
                        <option>31</option>
                    </select>
                </div>
                <div className="form-group col-sm-3 col-xs-6">
                    <label htmlFor="month" className="sr-only"></label>
                    <select className="form-control" id="month" onChange={value => handleChange('month', value)}>
                        <option value="month">Month</option>
                        <option value="01">Jan</option>
                        <option value="02">Feb</option>
                        <option value="03">Mar</option>
                        <option value="04">Apr</option>
                        <option value="05">May</option>
                        <option value="06">Jun</option>
                        <option value="07">Jul</option>
                        <option value="08">Aug</option>
                        <option value="09">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                    </select>
                </div>
                <div className="form-group col-sm-6 col-xs-3">
                    <label htmlFor="year" className="sr-only"></label>
                    <select className="form-control" id="year" onChange={value => handleChange('year', value)}>
                        <option value="year">Year</option>
                        {[...Array(100)].map((x, index) =>
                            <option key={index}>{new Date().getFullYear() - index}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="form-group gender col-sm-6 col-xs-12">
                    <label className="radio-inline">
                        <input type="radio" name="optradio" onChange={value => handleChange('isMale', value)} />Male
                    </label>
                    <label className="radio-inline">
                        <input type="radio" name="optradio" onChange={value => handleChange('isFemale', value)} />Female
                    </label>
                </div>
            </div>
            <div className="row">
                <p className="birth"><strong>Location</strong></p>
                <div className="form-group col-xs-6">
                    <label htmlFor="country" className="sr-only"></label>
                    <select className="form-control" id="country" onChange={(value) => handleChange('countryId', value)}>
                        <option>Please select</option>
                        {countries.map(country =>
                            <option key={country.id} value={country.id}>{country.title}</option>
                        )}
                    </select>
                </div>
                <div className="form-group col-xs-6">
                    <label htmlFor="city" className="sr-only"></label>
                    <input id="city" onChange={value => handleChange('city', value)} className="form-control input-group-lg" type="text" name="city" title="City" placeholder="City/Town (Optional)" />
                </div>
            </div>
            <div className="row">
                <div className="form-group gender col-sm-12 col-xs-12">
                    <label className="agreement-text">
                        <input type="checkbox" name="checkbox" onChange={value => handleChange('hasAgreed', value)} />
                        &nbsp;Please confirm that you agree to our <a href="#/" data-toggle="modal" data-target=".terms-modal">Terms and Conditions</a> and
                        &nbsp;<a href="#/" data-toggle="modal" data-target=".privacy-modal">Privacy Policy</a>.
                    </label>
                </div>
            </div>
        </form>
        <Validation validationErrors={validationErrors} />
        <button className="btn btn-primary" onClick={onRegisterClick}>Register Now</button>
        <p><a href="#/" onClick={() => { onTabChange('login') }}>Already have an account?</a></p>
    </div>

export default Register;