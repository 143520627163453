export const isRequired = fieldName => `${fieldName} is required`;

export const email = fieldName => `Email should be a valid email address`;

export const mustMatch = otherFieldName => {
    return (fieldName) => `${fieldName} must match ${otherFieldName}`;
};

export const minLength = length => {
    return (fieldName) => `${fieldName} must be at least ${length} characters`;
};

