import { useContext } from 'react';
import { resendValidation } from '../../actions/user';
import Context from '../../context';

function Status() {
    const { current } = useContext(Context);

    async function resendEmail() {
        await resendValidation(current);
    }
    return (
        <div className="status-container container">
            <div className="message">
                Please check your email account for a verification message and click on the verification link to activate your Whiskersite account. 
                If you did not receive the verification message, please <a onClick={resendEmail} href="#/">click here</a> for a resend.
                </div>
        </div>
    );
}

export default Status;
