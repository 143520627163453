import React from 'react';
import { Link } from 'react-router-dom';
import { userLink, isAnonymous } from '../../../utils/link';
import Search from './Search';
import { startTour } from '../../../utils/link';

const Header = ({ user, signOut, unreadChats }) =>
    <header id="header">
        <nav className="navbar navbar-default menu">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <Link className="navbar-brand" to={'/'}>
                        <img id="main-logo" className="img-responsive" src="/images/logo-transparent.png" alt="logo" />
                    </Link>
                </div>
                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav navbar-right main-menu">
                        {isAnonymous() &&
                            <li className="dropdown">
                                <Link className="welcome-link" onClick={() => startTour()}>Site Tour</Link>
                            </li>
                        }
                        <li className="dropdown">
                            <Link to={userLink(user)}>My Profile</Link>
                        </li>
                        <li className="dropdown">
                            <Link to={'/cat-share'}>CatShare</Link>
                        </li>
                        {!isAnonymous() &&
                            <li className="dropdown">
                                <Link to={'/new-cat-reps'}>New CatReps</Link>
                            </li>
                        }
                        {!isAnonymous() && user.waiting.length > 0 &&
                            <li>
                                <Link to={'/cat-rep-requests'} title="New CatRep request">
                                    <span className="badge">{user.waiting.length}</span>
                                    <img className="badge-icon" src="/images/add-friend.svg" alt="" />
                                </Link>
                            </li>
                        }
                        {!isAnonymous() && unreadChats.length > 0 &&
                            <li>
                                <Link to={`/cat-chat/${unreadChats[0].cat.id}`} title="New CatChat message">
                                    <span className="badge">{unreadChats[0].unreadCount}</span>
                                    <img className="badge-icon" src="/images/message.svg" alt="" />
                                </Link>
                            </li>
                        }
                        {!isAnonymous() &&
                            <li className="dropdown">
                                <a href="#/" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Me <span><img src="/images/down-arrow.png" alt="" /></span></a>
                                <ul className="dropdown-menu newsfeed-home">
                                    <li>
                                        <Link to={`/my-cats/${user.id}`}>My Cats</Link>
                                    </li>
                                    <li>
                                        <Link to={`/catchies/${user.id}`}>Catchies</Link>
                                    </li>
                                    <li>
                                        <a href="#/" onClick={signOut}>Sign Out</a>
                                    </li>
                                </ul>
                            </li>
                        }
                        {isAnonymous() &&
                            <>
                                <li className="dropdown">
                                    <Link to={'/welcome'} className="welcome-link">Login</Link>
                                </li>
                                <li className="dropdown text-blue">
                                    <Link to={'/welcome'} className="welcome-link">Register</Link>
                                </li>
                            </>
                        }
                    </ul>
                    <Search />
                </div>
            </div>
        </nav>
    </header>

export default Header;