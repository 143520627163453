import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Context from '../context';
import { anonymousToken } from '../configuration';
import { getCurrentUser } from '../actions/user';
import { injectAuthorizationToken } from '../utils/client';
import Header from '../components/Common/Header';
import Footer from '../components/Common/Footer';
import UserInformation from './User/Information';
import UserCatsList from '../components/User/UserCatsList';
import CatInformation from '../components/Cat/Information';
import Catalog from '../components/Cat/Catalog';
import Blog from '../components/Cat/Blog';
import CatFriendList from '../components/Cat/FriendList';
import Feed from '../components/Feed';
import PreLoader from './Common/PreLoader';
import FriendList from '../components/User/FriendList';
import FriendRequest from '../components/User/FriendRequest';
import UserList from '../components/User/List';
import Catchies from '../components/User/Catchies';
import CatChat from '../components/Cat/Chat';
import Status from '../components/Common/Status';
import AnonymousModal from './Common/AnonymousModal';

function App() {
    let { current, setCurrent } = useContext(Context);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (!current) {
                if (!window.localStorage.getItem('token')) {
                    window.localStorage.setItem('token', anonymousToken);
                }

                injectAuthorizationToken();
                const user = await getCurrentUser();
                if (user) {
                    setCurrent(user);
                } else {
                    history.push('/welcome');
                }
            }
        })();
    });

    if (!current) {
        return <PreLoader />;;
    }

    return (
        <>
            <div className="wrap">
                <Header />
                {current.status === 0 && <Status />}
                <Switch>
                    <Route path="/cat/:id/:name" component={CatInformation} />
                    <Route path="/cat-catalog/:id/:name?/:type?" component={Catalog} />
                    <Route path="/cat-catalog/:id/:name?" component={Catalog} />
                    <Route path="/cat-diary/:id/:name?" component={Blog} />
                    <Route path="/cat-mates/:id/:name?" component={CatFriendList} />
                    <Route path="/catchies/:id/:name?" component={Catchies} />
                    <Route path="/cat-chat/:id/:name?" component={CatChat} />
                    <Route path="/cat-rep/:id" component={UserInformation} />
                    <Route path="/my-cats/:id/:name?" component={UserCatsList} />
                    <Route path="/information" component={UserInformation} />
                    <Route path="/cat-reps/:id/:name?" component={FriendList} />
                    <Route path="/cat-rep-requests" component={FriendRequest} />
                    <Route path="/new-cat-reps/:type?" component={UserList} />
                    <Route path="/cat-share" component={Feed} />
                    <Redirect from="/" to="/cat-share" />
                </Switch>
                <AnonymousModal />
            </div>
            <Footer />

        </>
    )
}

export default App;