import React, { useContext } from 'react';
import UserTimeline from './UserTimeline';
import { addRelation, addProfilePhoto } from '../../../actions/user';
import Context from '../../../context';

function UserTimelineContainer(props) {
    const { current, setCurrent } = useContext(Context);

    function onConnect() {
        addRelation(props.user.id);
    }

    async function onSaveProfilePhoto(profilePhoto) {
        const newProfilePhoto = await addProfilePhoto(profilePhoto);
        setCurrent({
            ...current,
            profilePhoto: newProfilePhoto
        });
    }

    return (
        <UserTimeline
            user={props.user}
            activeMenu={props.activeMenu}
            onConnect={onConnect}
            onSaveProfilePhoto={onSaveProfilePhoto} />
    )
}

export default UserTimelineContainer;
