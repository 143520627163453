import React, { useState } from 'react';
import YoutubeModal from './YoutubeModal';
import { fileType } from '../../../utils/enum';
import { addYoutube } from '../../../actions/cat';
import { ruleRunner, run } from '../../../utils/validation';
import { required } from '../../../utils/validation/rules';
import { useHistory } from 'react-router-dom';

function YoutubeModalContainer(props) {
    const [url, setUrl] = useState('');
    const [validationErrors, setValidationErrors] = useState();
    const history = useHistory();

    async function handleChange(event) {
        setUrl(event.target.value)
    }

    async function saveUrl() {
        const newValidationErrors = run({ url }, fieldValidations);
        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        } else {
            setValidationErrors(null);
            await addYoutube(props.cat.id, fileType.youtube, url);
            history.push(`/cat-catalog/${props.cat.id}/${props.cat.name}/youtube`);
            window.location.reload();
        }
    }

    return (
        <YoutubeModal
            handleChange={handleChange}
            saveUrl={saveUrl}
            validationErrors={validationErrors} />
    )
}

const fieldValidations = [
    ruleRunner("url", "Youtube url", required),
];

export default YoutubeModalContainer;
