import React, { useState } from 'react';
import ForgotPassword from './ForgotPassword';
import { ruleRunner, run } from '../../../utils/validation';
import { email } from '../../../utils/validation/rules';
import { forgotPassword } from '../.././../actions/user';

function ForgotPasswordContainer() {
    const [model, setModel] = useState({});
    const [validationErrors, setValidationErrors] = useState();

    function onChange(event) {
        setModel({
            email: event.target.value
        });
    }

    async function onSendClick() {
        const newValidationErrors = run(model, fieldValidations);

        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        } else {
            forgotPassword(model.email);
            window.hideModal();
        }
    }

    return (
        <ForgotPassword
            onChange={onChange}
            onSendClick={onSendClick}
            validationErrors={validationErrors} />
    )
}

const fieldValidations = [
    ruleRunner("email", "Email", email)
];

export default ForgotPasswordContainer;
