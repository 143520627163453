import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { validateStatus } from '../../actions/user';

function Validate() {
    const { token } = useParams();
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        (async () => {
            await validateStatus(token);
            setValidated(true);
        })();
    },[token]);

    if (validated) {
        return <Redirect to="/" />
    }

    return null;
}

export default Validate;
