import React, { useCallback, useEffect, useState } from 'react';
import { ruleRunner, run } from '../../../../utils/validation';
import { required } from '../../../../utils/validation/rules';
import { editComment } from '../../../../actions/feed';
import EditComment from './EditComment';

function EditCommentContainer(props) {
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState();

    const setCommentCallback = useCallback(() => {
        const content = JSON.parse(props.comment.content);
        setMessage(content.message);
    }, [props.comment]);

    useEffect(() => {
        setCommentCallback();
    }, [setCommentCallback]);

    function onInputChange(field, value) {
        setMessage(value.target.value);
    }

    async function onSave() {
        const newValidationErrors = run({ message }, fieldValidations);
        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        } else {
            setValidationErrors(null);
            const content = { message };
            const editedComment = await editComment(props.feed, props.comment, content);
            const feed = {
                ...props.feed,
                comments: props.feed.comments.map((item) => item.id === editedComment.id ? editedComment : item)
            }

            props.onEdit(feed);
            window.hideModal();
        }
    }

    return (
        <EditComment
            comment={props.comment}
            onInputChange={onInputChange}
            message={message}
            validationErrors={validationErrors}
            onSave={onSave} />
    )
}

const fieldValidations = [
    ruleRunner("message", "Comment message", required),
];

export default EditCommentContainer;
