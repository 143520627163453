import React from 'react';
import EditComment from './EditComment';
import DeleteComment from './DeleteComment';
import MemberOnly from '../MemberOnly';

const CommentContextMenu = ({ feed, comment, onEdit }) =>
    <MemberOnly hide="true">
        <div className="comment-context-menu">
            <a href="#/" data-toggle="dropdown">
                <svg viewBox="0 0 24 24" width="24px" height="24px" x="0" y="0" preserveAspectRatio="xMinYMin meet" focusable="false">
                    <path d="M2,10H6v4H2V10Zm8,4h4V10H10v4Zm8-4v4h4V10H18Z" style={{ fill: 'currentColor' }}></path>
                </svg>
            </a>
            <ul className="dropdown-menu dropdown-menu-right">
                <li>
                    <a href="#/" data-toggle="modal" data-target={`.edit-comment-${comment.id}-modal`}>Edit</a>
                </li>
                <li>
                    <a href="#/" data-toggle="modal" data-target={`.delete-comment-${comment.id}-modal`}>Delete</a>
                </li>
            </ul>
            <EditComment feed={feed} comment={comment} onEdit={onEdit} />
            <DeleteComment feed={feed} comment={comment} onEdit={onEdit} />
        </div>
    </MemberOnly>
export default CommentContextMenu;