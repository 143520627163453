import React from 'react';
import ProfileCard from '../../Common/ProfileCard';
import UserList from '../../Common/UserList';

const FriendRequest = ({ user, users }) =>
    <div className="main">
        <div id="page-contents">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 static">
                        <ProfileCard user={user} />
                    </div>
                    <div className="col-md-7">
                        <h4 className="grey">
                            <i className="icon ion-android-checkmark-circle"></i>CatRep Requests
                    </h4>
                        <div className="line"></div>
                        {users.length > 0 ?
                            <p>Please click "Approve" button to accept each request</p> :
                            <p>You have no pending CatRep requests</p>
                        }
                        {users.length > 0 && <UserList user={user} users={users} />}
                    </div>
                    <div className="col-md-2 static">
                    </div>
                </div>
            </div>
        </div>

    </div>

export default FriendRequest;
