import React, { useCallback, useContext, useEffect, useState } from 'react';
import FriendList from './FriendList';
import { getCat, getFriends } from '../../../actions/cat';
import Scrollable from '../../Common/Scrollable';
import { useParams } from 'react-router-dom';
import Context from '../../../context';
import PreLoader from '../../Common/PreLoader';

function FriendListContainer() {
    const { id } = useParams();
    const { current } = useContext(Context);
    const [cat, setCat] = useState();
    const [friends, setFriends] = useState([]);

    const getFriendsCallback = useCallback(async (index = 0) => {
        const fetchedFriends = await getFriends(id, index);
        setFriends(friends => [
            ...friends,
            ...fetchedFriends.filter((cat) => !cat.deathdate)
        ]);
    }, [id]);

    useEffect(() => {
        (async () => {
            const fetchedCat = await getCat(id, current);
            setCat(fetchedCat);
            await getFriendsCallback()
        })();
    }, [id, current, getFriendsCallback]);

    if (!cat) {
        return <PreLoader />;;
    }

    return (
        <Scrollable count={friends} callback={getFriendsCallback}>
            <FriendList
                cat={cat}
                friends={friends} />
        </Scrollable>
    )
}

export default FriendListContainer;
