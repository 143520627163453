import React from 'react';
import Validation from '../Validation';

const VideoModal = ({ handleChange, saveUrl, validationErrors }) =>
    <div className="modal fade add-youtube-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title">Add Youtube Video</h4>
                </div>
                <div className="modal-body">
                    <form name="edit-feed-form" className="modal-form">
                        <div className="form-group">
                            <textarea onChange={handleChange} rows="2" name="texts" className="form-control" placeholder="Please enter your Youtube video url"></textarea>
                        </div>
                    </form>
                    <Validation validationErrors={validationErrors} />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={saveUrl}>Save changes</button>
                </div>
            </div>
        </div>
    </div>

export default VideoModal;