import React, { useState } from 'react';
import PhotoModal from './PhotoModal';

function PhotoModalContainer(props) {
    const [profilePhoto, setProfilePhoto] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState();

    async function handleFileUpload(event) {
        try {
            const reader = new FileReader();
            const file = event.target.files[0];

            setProfilePhoto(file);

            reader.readAsDataURL(file);

            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function saveFileUpload() {
        if (props.onSave) {
            props.onSave(profilePhoto);
        }

        setImagePreviewUrl(null);
    }

    return (
        <PhotoModal
            handleFileUpload={handleFileUpload}
            imagePreviewUrl={imagePreviewUrl}
            saveFileUpload={saveFileUpload}
            modalClass={props.modalClass} />
    )
}

export default PhotoModalContainer;
