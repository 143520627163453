import React from 'react';

function AnonymousModal() {
    return (
        <div className="modal fade anonymous-modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">Guest user</h4>
                    </div>
                    <div className="modal-body">
                        You are using Whiskersite as a guest.  Some features are disabled by default for the guest visitors.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnonymousModal;