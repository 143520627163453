import React, { useContext, useEffect, useState } from 'react';
import FriendRequest from './FriendRequest';
import { getWaiting } from '../../../actions/user';
import Context from '../../../context';

function FriendRequestContainer() {
    const { current } = useContext(Context);
    const [waiting, setWaiting] = useState([]);

    useEffect(() => {
        (async () => {
            const fetchedWaiting = await getWaiting(current.id);
            setWaiting(fetchedWaiting);
        })();
    }, [current]);

    return (
        <FriendRequest
            user={current}
            users={waiting} />
    )
}

export default FriendRequestContainer;
