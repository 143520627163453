import React from 'react';
import { Link } from 'react-router-dom';
import { catProfile } from '../../../utils/photo';
import PhotoModal from '../PhotoModal';
import CatChoose from '../CatChoose';
import { catLink } from '../../../utils/link';
import MemberOnly from '../../Common/MemberOnly';
import Tooltip from '../Tooltip';

const CatTimeline = ({ cat, activeMenu, hasDiaryVisible, isCatRepFriend, onSaveProfilePhoto }) => {
    const getCssClass = (menuName) => {
        const isCathiesMenu = ['chat', 'blog', 'friends'].filter((menu) => menu === menuName).length > 0;

        let linkClass = activeMenu === menuName ? "active" : (cat.deathdate && isCathiesMenu ? " dark-grey" : "");
        return linkClass
    }

    return (
        <>
            <Tooltip type="cat" />
            <PhotoModal modalClass="add-cat-profile-photo-modal" onSave={onSaveProfilePhoto} />
            <div className="timeline-cover">
                <div className="timeline-nav-bar hidden-sm hidden-xs">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="profile-info">
                                <MemberOnly>
                                    <a href="#/" className="profile-photo-link" data-toggle={cat.isMine && activeMenu === "about" ? "modal" : null} data-target=".add-cat-profile-photo-modal">
                                        <img src={catProfile(cat)} alt="" className="img-responsive profile-photo" />
                                    </a>
                                </MemberOnly>
                                <h3>{cat.name}</h3>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="tooltip_templates" style={{ display: 'none' }}>
                                <span id="catalog-tooltip">
                                    <div className="tooltip-content">
                                        <img className="img-responsive" alt="" src="/images/hover/hover-catalog.jpg" />
                                        <div>
                                            <span>CatReps can create an album/documentary of their cats by uploading their photographs and videos</span>
                                        </div>
                                    </div>
                                </span>
                                <span id="blog-tooltip">
                                    <div className="tooltip-content">
                                        <img className="img-responsive" alt="" src="/images/hover/hover-diary.png" />
                                        <div>
                                            <span>Cats’ daily life experience and notes through the eyes of their CatReps</span>
                                        </div>

                                    </div>
                                </span>
                                <span id="chat-tooltip">
                                    <div className="tooltip-content">
                                        <img className="img-responsive" alt="" src="/images/hover/hover-chat.png" />
                                        <div>
                                            <span>Cat accounts (via the CatReps) can chat with each other</span>
                                        </div>
                                    </div>
                                </span>
                                <span id="mate-tooltip">
                                    <div className="tooltip-content">
                                        <img className="img-responsive" alt="" src="/images/hover/hover-catmate.png" />
                                        <div>
                                            <span>Cat friends connected via CatRep accounts</span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <ul className="list-inline profile-menu">
                                <li id="timeline-catId">
                                    <Link className={getCssClass("about")} to={catLink(cat)}>CatID</Link>
                                </li>
                                <li id="timeline-catalog" className="custom-tooltip" data-tooltip-content="#catalog-tooltip">
                                    <Link id="catalog-link" className={getCssClass("catalog")} to={`/cat-catalog/${cat.id}/${cat.name}`}>
                                        CatAlog
                                    </Link>
                                </li>
                                {hasDiaryVisible &&
                                    <li id="timeline-catblog" className="custom-tooltip" data-tooltip-content="#blog-tooltip">
                                        <Link id="catdiary-link" className={getCssClass("blog")} to={`/cat-diary/${cat.id}/${cat.name}`}>
                                            CatBlog
                                        </Link>
                                    </li>
                                }
                                {cat.isMine &&
                                    <li id="timeline-catchat" className="custom-tooltip" data-tooltip-content="#chat-tooltip">
                                        <Link id="catchat-link" className={getCssClass("chat")} to={`/cat-chat/${cat.id}/${cat.name}`}>
                                            CatChat
                                        </Link>
                                    </li>
                                }
                                <li id="timeline-catmates" className="custom-tooltip" data-tooltip-content="#mate-tooltip">
                                    <Link id="catmates-link" className={getCssClass("friends")} to={`/cat-mates/${cat.id}/${cat.name}`}>CatMates</Link>
                                </li>
                            </ul>
                            <ul className="follow-me list-inline">
                                {activeMenu === "chat" && <li>
                                    <MemberOnly>
                                        <button id="start-catchat" className="btn-primary" data-toggle="modal" data-target=".cat-choose-modal">Start CatChat</button>
                                    </MemberOnly>
                                </li>}

                                {!cat.isMine && isCatRepFriend && <li>
                                    <MemberOnly>
                                        <button className="btn-primary" data-toggle="modal" data-target=".cat-choose-modal">Send Message</button>
                                    </MemberOnly>
                                </li>}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="navbar-mobile hidden-lg hidden-md">
                    <div className="profile-info">
                        <MemberOnly>
                            <a href="#/" className="profile-photo-link" data-toggle={cat.isMine && activeMenu === "about" ? "modal" : null} data-target=".add-cat-profile-photo-modal">
                                <img src={catProfile(cat)} alt="" className="img-responsive profile-photo" />
                            </a>
                        </MemberOnly>
                        <h4>{cat.name}</h4>
                    </div>
                    <div className="mobile-menu">
                        <ul className="list-inline">
                            <li id="timeline-catId-sm">
                                <Link className={getCssClass("about")} to={catLink(cat)}>CatID</Link>
                            </li>
                            <li id="timeline-catalog-sm" className="custom-tooltip" data-tooltip-content="#catalog-tooltip">
                                <Link className={getCssClass("catalog")} to={`/cat-catalog/${cat.id}/${cat.name}`}>
                                    CatAlog
                                    </Link>
                            </li>
                            {hasDiaryVisible &&
                                <li id="timeline-catblog-sm" className="custom-tooltip" data-tooltip-content="#blog-tooltip">
                                    <Link className={getCssClass("blog")} to={`/cat-diary/${cat.id}/${cat.name}`}>
                                        CatBlog
                                    </Link>
                                </li>
                            }
                            {cat.isMine &&
                                <li id="timeline-catchat" className="custom-tooltip" data-tooltip-content="#chat-tooltip">
                                    <Link className={getCssClass("chat")} to={`/cat-chat/${cat.id}/${cat.name}`}>
                                        CatChat
                                        </Link>
                                </li>
                            }
                            <li id="timeline-catmates-sm" className="custom-tooltip" data-tooltip-content="#mate-tooltip">
                                <Link className={getCssClass("friends")} to={`/cat-mates/${cat.id}/${cat.name}`}>CatMates</Link>
                            </li>
                        </ul>
                        <ul className="follow-me list-inline">
                            {activeMenu === "chat" && <li>
                                <MemberOnly>
                                    <button id="start-catchat-sm" className="btn-primary" data-toggle="modal" data-target=".cat-choose-modal">Start CatChat</button>
                                </MemberOnly>
                            </li>}
                            {!cat.isMine && isCatRepFriend && <li>
                                <MemberOnly>
                                    <button className="btn-primary" data-toggle="modal" data-target=".cat-choose-modal">Send Message</button>
                                </MemberOnly>
                            </li>}
                        </ul>
                    </div>
                </div>
            </div>

            <CatChoose cat={cat} />
        </>
    )
}

export default CatTimeline;