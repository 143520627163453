import axios from 'axios';
import { apiUrl } from '../configuration';
import { injectAuthorizationToken } from '../utils/client';
import toastr from 'toastr';

const setLocalStorage = (key, value) => {
    const storage = window.localStorage;
    storage.setItem(key, value);
}

const removeLocalStorage = () => {
    const storage = window.localStorage;
    storage.removeItem('token');
    storage.removeItem('status');
}

export const register = async (registerModel) => {
    const apiRequest = {
        firstname: registerModel.firstname,
        lastname: registerModel.lastname,
        email: registerModel.email,
        password: registerModel.password,
        birthdate: `${registerModel.year}-${registerModel.month}-${registerModel.day}`,
        gender: registerModel.isMale === "on",
        country: { id: registerModel.countryId },
        city: registerModel.city,
    }

    let apiResponse;

    try {
        apiResponse = await axios.post(`${apiUrl}/users`, apiRequest);
        const token = apiResponse.data;

        setLocalStorage('token', token);
        injectAuthorizationToken();

        toastr.success('Your account created successfully');

        return true;
    } catch (error) {
        toastr.error(error.response.data.message);
        return false;
    }
}

export const login = async (email, password) => {
    try {
        const apiResponse = await axios.post(`${apiUrl}/users/login`, { email: email, password: password });
        const token = apiResponse.data;

        setLocalStorage('token', token);
        injectAuthorizationToken();

        toastr.success('You successfully logged in');
        return true;
    } catch (error) {
        toastr.error(error.response.data.message);
        return false;
    }
}

export const getFriends = async (userId) => {
    try {
        const friendsResponse = await axios(`${apiUrl}/users/${userId}/friends`);
        return friendsResponse.data;
    } catch (error) {
        toastr.error(error);
    }
}

export const getRequested = async (userId) => {
    try {
        const requestedResponse = await axios(`${apiUrl}/users/${userId}/requested`);
        return requestedResponse.data;
    } catch (error) {
        toastr.error(error);
    }
}

export const getWaiting = async (userId) => {
    try {
        const waitingResponse = await axios(`${apiUrl}/users/${userId}/waiting`);
        return waitingResponse.data;
    } catch (error) {
        toastr.error(error.response.data.message);
    }
}

export const getCurrentUser = async () => {
    try {
        const userResponse = await axios(`${apiUrl}/users/user`);
        const catsReponse = await axios(`${apiUrl}/cats?userId=${userResponse.data.id}`);
        const friendsResponse = await axios(`${apiUrl}/users/${userResponse.data.id}/friends`);
        const unreadChatsResponse = await axios(`${apiUrl}/chats?userId=${userResponse.data.id}&unread=true`);
        const waitingResponse = await axios(`${apiUrl}/users/${userResponse.data.id}/waiting`);

        const user = {
            ...userResponse.data,
            cats: catsReponse.data,
            friends: friendsResponse.data,
            unreadChats: unreadChatsResponse.data,
            waiting: waitingResponse.data
        };

        setLocalStorage('status', user.status);
        return user;
    } catch (error) {
        removeLocalStorage();
    }
}

export const getUser = async (userId, current) => {
    try {
        const userResponse = await axios(`${apiUrl}/users/${userId}`);
        const catsReponse = await axios(`${apiUrl}/cats?userId=${userId}`);

        let user = userResponse.data;

        user = {
            ...user,
            cats: catsReponse.data,
            isMe: user.id === current.id,
            isFriend: current.friends.some((friend) => friend.id === user.id)
        }

        return user;
    } catch (error) {
        toastr.error(error.response.data.message);
    }
}

//toDo current
export const getUsers = async (current, type, index = 0) => {
    try {
        let usersResponse;
        if (type === "waiting") {
            usersResponse = await axios(`${apiUrl}/users/${current.id}/waiting`);
        } else if (type === "requested") {
            usersResponse = await axios(`${apiUrl}/users/${current.id}/requested`);
        } else {
            usersResponse = await axios(`${apiUrl}/users?index=${index}&pageSize=50&orderBy=id&sort=desc`);
        }

        return usersResponse.data;
    } catch (error) {
        toastr.error(error.response.data.message);
    }
}

export const searchUsers = async (name) => {
    try {
        if (!name) {
            return [];
        }

        const usersResponse = await axios(`${apiUrl}/users?name=${name}&pageSize=10`);

        return usersResponse.data;
    } catch (error) {
        toastr.error(error.response.data.message);
    }
}

export const signOut = (setCurrent) => {
    setCurrent(null);
    removeLocalStorage();
}

export const addProfilePhoto = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const apiResponse = await axios.put(`${apiUrl}/users/user/photo`, formData);

        toastr.success('Photo uploaded successfully');

        return apiResponse.data.profilePhoto

    } catch (error) {
        toastr.error('Problem occured during photo upload.');
    }
}

export const addRelation = async (toUserId) => {
    try {
        await axios.post(`${apiUrl}/users/${toUserId}/relations`);

        toastr.success(`Your friend request sent.`);

        return toUserId;
    } catch (error) {
        toastr.error('Friend request waiting approvement.');
    }
}

export const approveRelation = async (toUserId) => {
    try {
        await axios.put(`${apiUrl}/users/${toUserId}/relations`, {
            approved: true
        });

        toastr.success(`You became friends successfully.`);

        return toUserId;
    } catch (error) {
        toastr.error('Problem occured during adding blog.');
    }
}

export const editUser = async (userModel) => {
    try {
        await axios.put(`${apiUrl}/users/${userModel.id}`, {
            ...userModel,
            country: { id: userModel.countryId }
        });

        toastr.success('Your profile saved successfully');
        return true;
    } catch (error) {
        toastr.error(error.response.data.message);
        return false;
    }
}

export const changePasswordWelcome = async (token, password) => {
    try {
        await axios.post(`${apiUrl}/users/changepassword`, {
            passwordToken: token,
            password: password
        });

        toastr.success('Your password changed successfully');
        return true;

    } catch (error) {
        toastr.error('This link isn\'t valid anymore. Please go to the login page and click the \'forget password\' link again.');
        return false;
    }
}

export const changePassword = async (current, password) => {
    try {
        await axios.put(`${apiUrl}/users/${current.id}`, {
            password: password
        });

        toastr.success('Your password changed successfully');

        return true;

    } catch (error) {
        toastr.error(error.response.data.message);
        return false;
    }
}

export const getFavorites = async (userId, index = 0) => {
    try {
        const favoritesResponse = await axios(`${apiUrl}/users/${userId}/files?index=${index}`);
        return favoritesResponse.data;
    } catch (error) {
        toastr.error(error.response.data.message);
    }
}

export const addUserFavorite = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const apiResponse = await axios.post(`${apiUrl}/users/files`, formData);

        toastr.success('Photo uploaded successfully');

        return apiResponse.data;

    } catch (error) {
        toastr.error('Problem occured during photo upload.');
    }
}

export const deleteFavorite = async (fileId) => {
    try {
        await axios.delete(`${apiUrl}/users/files/${fileId}`);
        toastr.success('Photo deleted successfully');
        return {
            id: fileId
        };
    } catch (error) {
        toastr.error(error.response.data.message);
    }
}

export const forgotPassword = async (email) => {
    try {
        await axios.post(`${apiUrl}/users/forgotpassword`, {
            email: email
        });

        toastr.success('An email sent to your email address. Please check your mailbox.');
        return true;
    } catch (error) {
        toastr.error('You should wait 10 minutes to send another email.');
        return false;
    }
}

export const contact = async (contact) => {
    try {
        await axios.post(`${apiUrl}/users/contact`, contact);

        toastr.success('We have received your message and would like to thank you for writing to us.');
        return true;
    } catch (error) {
        toastr.error('You should wait 10 minutes to send another message.');
        return false;
    }
}

export const resendValidation = async (user) => {
    try {
        await axios.post(`${apiUrl}/users/revalidate`, user);
        toastr.success('A validation link sent to your email address. Please check your mailbox.');
    } catch (error) {
        toastr.error('You should wait 10 minutes to send another email.');
    }
}

export const validateStatus = async (statusToken) => {
    try {
        const userResponse  = await axios.post(`${apiUrl}/users/validate`, {
            statusToken
        });

        setLocalStorage('token', userResponse.data.token);
        injectAuthorizationToken();
        
        toastr.success('Your account validated successfully');
        return true;
    } catch (error) {
        toastr.error(error.response.data.message);
        return false;
    }
}