import React from 'react';

const PhotoModal = ({ handleFileUpload, imagePreviewUrl, saveFileUpload, modalClass }) =>
    <div className={`modal fade ${modalClass}`} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title">Upload photo</h4>
                </div>
                <div className="modal-body">
                    <p>
                        <span className="btn btn-default btn-file">
                            Choose your photo <input type="file" onChange={handleFileUpload} />
                        </span>
                    </p>
                    {imagePreviewUrl && <img className="photo-preview" src={imagePreviewUrl} alt="" />}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveFileUpload}>Save changes</button>
                </div>
            </div>
        </div>
    </div>

export default PhotoModal;