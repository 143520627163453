import React from 'react';
import { feedType } from '../../../../utils/enum';

const DeleteFeed = ({ feed, onDelete }) => {
    const text = feed.type === feedType.blog ? 'Diary' : 'Share';

    return (
        <div className={`modal fade delete-feed-${feed.id}-modal`} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">Delete {text}</h4>
                    </div>
                    <div className="modal-body">
                        <form name="edit-feed-form" className="modal-form">
                            <div className="form-group">
                                <p>Are you sure you want to delete this {text}?</p>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={onDelete}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default DeleteFeed;