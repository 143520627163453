import React from 'react';
import { Link } from 'react-router-dom';
import { userProfile } from '../../../utils/photo';
import { userLink } from '../../../utils/link';
import MemberOnly from '../MemberOnly';

const UserList = ({ users, onVisit, onConnect, onApprove }) =>
    <div className="people-nearby">
        {users.map((friend) => {
            return (
                <div key={friend.id} className="nearby-user">
                    <div className="row">
                        <div className="col-md-2 col-sm-2 col-xs-2">
                            <img src={userProfile(friend)} alt="user" className="profile-photo-lg" />
                        </div>
                        <div className="col-md-7 col-sm-7 col-xs-6">
                            <h5>
                                <Link className="profile-link" to={userLink(friend)}>{`${friend.firstname} ${friend.lastname}`}</Link>
                            </h5>
                            {friend.country && <p>{friend.country.title} {friend.city ? `- ${friend.city}` : ''}</p>}
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-2">
                            {friend.isFriend && <button className="btn btn-primary pull-right" onClick={() => { onVisit(friend) }} >Visit</button>}
                            {friend.isRequested && <MemberOnly><button className="btn btn-primary pull-right">Requested</button></MemberOnly>}
                            {friend.isWaiting && <MemberOnly><button className="btn btn-primary pull-right" onClick={() => { onApprove(friend) }}>Approve</button></MemberOnly>}
                            {friend.notConnected && <MemberOnly><button className="btn btn-primary pull-right" onClick={() => { onConnect(friend) }} >Connect</button></MemberOnly>}
                        </div>
                    </div>
                </div>
            )
        })}
    </div>

export default UserList;
