import React from 'react';
import Validation from '../../Common/Validation';

const PasswordModal = ({ user, onChange, validationErrors, onSave }) => {
    return (
        <div className="modal fade password-modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">Change Password</h4>
                    </div>
                    <div className="modal-body">
                        <form name="settings-form" className="modal-form">
                            <div className="form-group">
                                <label>* Password</label>
                                <input onChange={value => onChange('password', value)} className="form-control" type="password" placeholder="Password" />
                            </div>
                            <div className="form-group">
                                <label>* Password again</label>
                                <input onChange={value => onChange('password2', value)} className="form-control" type="password" placeholder="Password again" />
                            </div>
                        </form>
                        <Validation validationErrors={validationErrors} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={onSave}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordModal;