import axios from 'axios';
import { apiUrl } from '../configuration';
import { feedType } from '../utils/enum';
import toastr from 'toastr';

export const previewLink = async (url) => {
    const response = await axios(`https://api-cdn.embed.ly/1/card-details`, {
        params: {
            card: 1,
            key: 'fd92ebbc52fc43fb98f69e50e7893c13',
            native: true,
            scheme: 'http',
            urls: url,
            v: 'MTcyMDEw',
            youtube_showinfo: 0
        }
    });

    return response.data.length > 0 && response.data[0];
}

export const createContent = async (text) => {
    let content;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = text.match(urlRegex) || [];

    if (links.length > 0) {
        const link = links[0];
        const preview = await previewLink(link);
        content = {
            message: text,
            link: link,
            title: preview.title,
            description: preview.description,
            image: preview.images.length > 0 && preview.images[0].url,
            url: preview.url
        }
    }
    else {
        content = {
            message: text
        }
    }

    return content;
}

export const getFeeds = async (index = 0) => {
    const getFeedsResponse = await axios(`${apiUrl}/feeds?index=${index}`);
    return getFeedsResponse.data;
}

export const addFeed = async (content, type, photo) => {
    try {
        const addFeedResponse = await axios.post(`${apiUrl}/feeds`, {
            type: type || feedType.feed,
            content: JSON.stringify(content)
        });

        const feed = { ...addFeedResponse.data, files: [], comments: [] };

        let addFileResponse;
        if (photo) {
            const formData = new FormData();
            formData.append('file', photo);

            addFileResponse = await axios.post(`${apiUrl}/feeds/${feed.id}/files`, formData);
            feed.files.push(addFileResponse.data);
        }

        toastr.success(`CatShare added successfully`);

        return feed;

    } catch (error) {
        toastr.error('Problem occured during adding CatShare.');
    }
}

export const editFeed = async (feed) => {
    try {
        const editFeedResponse = await axios.put(`${apiUrl}/feeds/${feed.id}`, feed);
        toastr.success(`Cat ${feed.type === feedType.blog ? 'Diary' : 'Share'} edited successfully`);
        return editFeedResponse.data;
    } catch (error) {
        toastr.error('Problem occured during editing.');
    }
}

export const deleteFeed = async (feed) => {
    try {
        await axios.delete(`${apiUrl}/feeds/${feed.id}`);

        toastr.success(`Cat ${feed.type === feedType.blog ? 'Diary' : 'Share'} deleted successfully`);

        return feed
    } catch (error) {
        toastr.error('Problem occured during deletion.');
    }
}

export const likeFeed = async (feedId) => {
    try {
        const likeFeedResponse = await axios.post(`${apiUrl}/feeds/${feedId}/likes`);
        return likeFeedResponse.data;

    } catch (error) {
        toastr.error('Problem occured during adding like.');
    }
}

export const addComment = async (feedId, content) => {
    try {
        const addCommentResponse = await axios.post(`${apiUrl}/feeds/${feedId}/comments`, {
            content: JSON.stringify(content)
        });
        return addCommentResponse.data;

    } catch (error) {
        toastr.error('Problem occured during adding comment.');
    }
}

export const editComment = async (feed, comment, content) => {
    try {
        const editCommentResponse = await axios.put(`${apiUrl}/feeds/${feed.id}/comments/${comment.id}`, {
            content: JSON.stringify(content)
        });
        toastr.success(`Comment edited successfully`);
        return editCommentResponse.data;
    } catch (error) {
        toastr.error('Problem occured during editing.');
    }
}

export const deleteComment = async (feed, comment) => {
    try {
        await axios.delete(`${apiUrl}/feeds/${feed.id}/comments/${comment.id}`);
        toastr.success(`Comment deleted successfully`);
    } catch (error) {
        toastr.error('Problem occured during deletion.');
    }
}
