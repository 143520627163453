import React, { useContext, useEffect, useState } from 'react';
import Feed from './Feed';
import { getFeeds, createContent, addFeed } from '../../actions/feed';
import { feedType } from '../../utils/enum';
import Scrollable from '../Common/Scrollable';
import Context from '../../context';

function FeedContainer() {
    const [feeds, setFeeds] = useState([]);
    const [model, setModel] = useState({});
    const [isFixed, setFixed] = useState(false);
    const { current } = useContext(Context);

    useEffect(() => {
        (async () => {
            const fetchedFeeds = await getFeeds();
            setFeeds(fetchedFeeds);
            document.addEventListener('scroll', onScroll);
        })();

        return () => {
            document.removeEventListener('scroll', onScroll);
        }
    }, []);

    function onScroll(event) {
        const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
        if (scrollTop > 250) {
            setFixed(true);
        } else {
            setFixed(false);
        }
    }

    function onPhotoSave(photo) {
        setModel({
            ...model,
            photo: photo
        });
    }

    function onInputChange(field, value) {
        setModel({
            ...model,
            [field]: value.target.value
        });
    }

    async function onPublish() {
        const content = model.message ? await createContent(model.message) : '';
        const createdFeed = await addFeed(content, feedType.feed, model.photo);

        setFeeds([
            createdFeed,
            ...feeds
        ]);
        setModel({
            message: '',
            photo: null
        });
    }

    async function getNewFeeds(index) {
        const newFeeds = await getFeeds(index);
        setFeeds(feeds => [
            ...feeds,
            ...newFeeds
        ]);
    }

    function onEdit(feed) {
        setFeeds(feeds.map((item) => item.id === feed.id ? feed : item));
    }

    function onDelete(feed) {
        setFeeds(feeds.filter((item) => item.id !== feed.id));
    }

    if (feeds.length === 0) {
        return null;
    }

    return (
        <Scrollable count={feeds.length} callback={getNewFeeds}>
            <Feed
                current={current}
                feeds={feeds}
                onInputChange={onInputChange}
                message={model.message}
                onPublish={onPublish}
                onPhotoSave={onPhotoSave}
                onEdit={onEdit}
                onDelete={onDelete}
                isFixed={isFixed} />
        </Scrollable>
    )
}

export default FeedContainer;
