import React from 'react';
import ProfileCard from '../../Common/ProfileCard';
import UserList from '../../Common/UserList';

const List = ({ current, users, history, onFilterChange }) =>
    <div className="main">
        <div id="page-contents">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 static">
                        <ProfileCard user={current} />
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-md-9">
                                <h4 className="grey">
                                    <i className="icon ion-android-checkmark-circle"></i>New CatReps
                                </h4>
                            </div>
                            <div className="col-md-3 align-right">
                                <select onChange={onFilterChange}>
                                    <option value="">Filter CatReps</option>
                                    <option value="">All</option>
                                    <option value="waiting">Waiting for your approval</option>
                                    <option value="requested">Requested by you</option>
                                </select>
                            </div>
                        </div>
                        <div className="line"></div>
                        <UserList user={current} users={users} history={history} />
                    </div>
                    <div className="col-md-2 static">
                    </div>
                </div>
            </div>
        </div>
    </div>

export default List;
