import React, { useContext, useEffect, useState } from 'react';
import UserCatsList from './UserCatsList';
import { getUser } from '../../../actions/user';
import { useParams } from 'react-router-dom';
import Context from '../../../context';
import PreLoader from '../../Common/PreLoader';

function UserCatslistContainer() {
    const { id } = useParams();
    const [user, setUser] = useState();
    const { current } = useContext(Context);

    useEffect(() => {
        (async () => {
            const fetchedUser = await getUser(id, current);
            setUser(fetchedUser);
        })();
    }, [id, current]);

    if (!user) {
        return <PreLoader />;
    }

    return (
        <>
            <UserCatsList user={user} />
        </>
    )
}

export default UserCatslistContainer;
