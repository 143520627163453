export const feedType = {
    blog: 1,
    feed: 2,
    photo: 3,
    video: 4,
    diary: 5,
    youtube: 6
}

export const fileType = {
    photo: 1,
    video: 2,
    blogPhoto: 3,
    feedPhoto: 4,
    youtube: 5
}

export const diaryType = {
    everyone: 1,
    catRep: 2,
    onlyMe: 3
}

export const introType = {
    user: 1,
    myCats: 2,
    catDetail: 3,
    catalog: 4,
    blog: 5,
    catMate: 6,
    catShare: 7,
    welcome: 8,
    chat: 9
}