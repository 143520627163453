import React from 'react'
import { Link } from 'react-router-dom';
import { startTour } from '../../utils/link';

function Footer() {
    function onIntroClick() {
        startTour();
    }

    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="footer-wrapper">
                        <div className="col-md-3 col-sm-3 col-xs-3">
                            <h5><Link to="/about">About</Link></h5>
                        </div>
                        <div className="col-md-2 col-sm-2 col-xs-2">
                            <h5><a href="#/" onClick={onIntroClick}>Site Tour</a></h5>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-3">
                            <h5><Link to="/terms">Terms</Link></h5>
                        </div>
                        <div className="col-md-2 col-sm-2 col-xs-2">
                            <h5><Link to="/privacy">Privacy</Link></h5>
                        </div>
                        <div className="col-md-2 col-sm-2 col-xs-2">
                            <h5><Link to="/contact">Contact</Link></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>whiskersite © 2020. All rights reserved</p>
            </div>
        </footer>
    )
}

export default Footer;