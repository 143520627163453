import React from 'react';
import EditFeed from './EditFeed';
import DeleteFeed from './DeleteFeed';
import MemberOnly from '../MemberOnly';

const PostContextMenu = ({ feed, onEdit, onDelete }) =>
    <MemberOnly hide="true">
        <div className="context-menu">
            <a href="#/" data-toggle="dropdown">
                <svg viewBox="0 0 24 24" width="24px" height="24px" x="0" y="0" preserveAspectRatio="xMinYMin meet" focusable="false">
                    <path d="M2,10H6v4H2V10Zm8,4h4V10H10v4Zm8-4v4h4V10H18Z" style={{ fill: 'currentColor' }}></path>
                </svg>
            </a>
            <ul className="dropdown-menu dropdown-menu-right">
                <li>
                    <a href="#/" data-toggle="modal" data-target={`.edit-feed-${feed.id}-modal`}>Edit</a>
                </li>
                <li>
                    <a href="#/" data-toggle="modal" data-target={`.delete-feed-${feed.id}-modal`}>Delete</a>
                </li>
            </ul>
            <EditFeed feed={feed} onEdit={onEdit} />
            <DeleteFeed feed={feed} onDelete={onDelete} />
        </div>
    </MemberOnly>
export default PostContextMenu;