import React from 'react';
import { deleteFeed } from '../../../../actions/feed';
import DeleteFeed from './DeleteFeed';

function DeleteFeedContainer(props) {
    async function onDelete() {
        await deleteFeed(props.feed);
        window.hideModal();
        props.onDelete(props.feed);
    }

    return (
        <DeleteFeed
            feed={props.feed}
            onDelete={onDelete} />
    )
}

export default DeleteFeedContainer;
