import React from 'react';
import Footer from '../Footer';
import Validation from '../../Common/Validation';

const Contact = ({ handleChange, onContactClick, validationErrors }) =>
    <>
        <div id="lp-register">
            <div className="wrap">
                <div className="main">
                    <header id="header-inverse">
                        <nav>
                            <div className="container">
                                <div className="logo-container">
                                    <a className="logo" href="/"><img className="img-responsive" src="images/logo-transparent.png" alt="logo" /></a>
                                    <span></span>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="intro-texts">
                                </div>
                            </div>
                            <div className="col-sm-6 col-sm-offset-1">
                                <div className="panel-group" id="accordion">
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Contact Form</a>
                                            </h4>
                                        </div>
                                        <div id="collapse1" className="panel-collapse collapse in">
                                            <div className="panel-body">
                                                <form name="ChangePassword_form" id='ChangePassword_form'>
                                                    <div className="row">
                                                        <div className="form-group col-xs-12">
                                                            <label className="sr-only">Name</label>
                                                            <input className="form-control input-group-lg" onChange={(value) => handleChange('name', value)} type="text" name="name" title="Enter your name" placeholder="Your name" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-12">
                                                            <label className="sr-only">Email</label>
                                                            <input className="form-control input-group-lg" onChange={(value) => handleChange('email', value)} type="text" name="email" title="Enter your email" placeholder="Your email" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-12">
                                                            <label className="sr-only">Subject</label>
                                                            <input className="form-control input-group-lg" onChange={(value) => handleChange('subject', value)} type="text" name="subject" title="Enter the subject" placeholder="Subject" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-xs-12">
                                                            <label className="sr-only">Message</label>
                                                            <textarea rows="5" className="form-control input-group-lg" onChange={(value) => handleChange('message', value)} placeholder="Your message"></textarea>
                                                        </div>
                                                    </div>
                                                </form>
                                                <Validation validationErrors={validationErrors} />
                                                <button className="btn btn-primary margin-right-20" onClick={onContactClick}>Send Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
            <div className="modal fade privacy-modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">Privacy  - Terms & Conditions</h4>
                        </div>
                        <div className="modal-body">
                            <div>
                                Lorem ipsum doler sit amet
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

export default Contact;