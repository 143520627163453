import React from 'react';
import { deleteComment } from '../../../../actions/feed';
import DeleteComment from './DeleteComment';

function DeleteCommentContainer(props) {
    async function onDelete() {
        await deleteComment(props.feed, props.comment);

        const feed = {
            ...props.feed,
            comments: props.feed.comments.filter((item) => item.id !== props.comment.id)
        }

        props.onEdit(feed);
        window.hideModal();
    }

    return (
        <DeleteComment
            comment={props.comment}
            onDelete={onDelete} />
    )
}

export default DeleteCommentContainer;
