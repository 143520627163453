import React from 'react';
import { Link } from 'react-router-dom';
import { catProfile } from '../../../utils/photo'
import { catLink } from '../../../utils/link';

const CatChoose = ({ cats, onChoose, areMyFriends }) =>
    <div className="modal fade cat-choose-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title">Please choose cat</h4>
                </div>
                <div className="modal-body">
                    <div className="people-nearby">
                        {cats.map((cat) =>
                            <div key={cat.id} className="nearby-user">
                                <div className="row">
                                    <div className="col-md-2 col-sm-2 col-xs-2">
                                        <img src={catProfile(cat)} alt="user" className="profile-photo-lg" />
                                    </div>
                                    <div className="col-md-7 col-sm-7 col-xs-6">
                                        <h5>
                                            <Link target="_blank" className="profile-link" to={catLink(cat)}>{`${cat.name}`}</Link>
                                        </h5>
                                        {cat.country && <p>{cat.country.title} {cat.city ? `- ${cat.city}` : ''}</p>}
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-2">
                                        <button className="btn btn-primary pull-right" onClick={() => onChoose(cat.id)} >Select</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {cats.length === 0 &&
                            <div>
                                {areMyFriends ?
                                    <>
                                        <h4 className="grey">You don't have any CatMate yet.</h4>
                                        <h4><a href="/new-cat-reps">Would you want to discover new CatReps ?</a></h4>
                                    </> :
                                    <>
                                        <h4 className="grey">You didn't added any active Cat accounts.</h4>
                                        <h4><a href="/information">Would you want to add new cat?</a></h4>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

export default CatChoose;
