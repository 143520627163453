import axios from 'axios';
import { apiUrl } from '../configuration';
import { fileType, feedType } from '../utils/enum';
import toastr from 'toastr';

export const addEditCat = async (catModel) => {
    const apiRequest = {
        name: catModel.name,
        birthDay: catModel.birthDay,
        birthMonth: catModel.birthMonth,
        birthYear: catModel.birthYear,
        deathdate: catModel.hasPassedAway && catModel.deathDay && catModel.deathMonth && catModel.deathYear ? `${catModel.deathYear}-${catModel.deathMonth}-${catModel.deathDay}` : null,
        gender: catModel.isMale === "on",
        breed: catModel.breed,
        location: catModel.location,
        country: catModel.countryId ? { id: catModel.countryId } : null,
        city: catModel.city,
        diaryType: catModel.diaryType
    }

    try {
        let catReponse;
        if (catModel.id) {
            catReponse = await axios.put(`${apiUrl}/cats/${catModel.id}`, apiRequest);
        } else {
            catReponse = await axios.post(`${apiUrl}/cats`, apiRequest);
        }

        toastr.success('Your cat saved successfully');
        return catReponse.data;
    } catch (error) {
        toastr.error(error.response.data.message);
        return false;
    }
}

export const getCat = async (id, current) => {
    const getCatResponse = await axios(`${apiUrl}/cats/${id}`);
    const cat = getCatResponse.data;
    return {
        ...cat,
        isMine: cat.user.id === current.id
    };
}

export const getFiles = async (id, type, index = 0) => {
    const getFilesResponse = await axios(`${apiUrl}/cats/${id}/files?type=${type}&index=${index}`);
    return getFilesResponse.data;
}

export const searchCats = async (name) => {
    try {
        if (!name) {
            return [];
        }

        const catsResponse = await axios(`${apiUrl}/cats/search?name=${name}`);
        return catsResponse.data;
    } catch (error) { }
}

export const addProfilePhoto = async (catId, file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const apiResponse = await axios.put(`${apiUrl}/cats/${catId}/photo`, formData);

        toastr.success('Photo uploaded successfully');

        return apiResponse.data;
    } catch (error) {
        toastr.error('Problem occured during photo upload.');
    }
}

export const addFile = async (catId, type, file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const apiResponse = await axios.post(`${apiUrl}/cats/${catId}/files?type=${type}`, formData);

        toastr.success(`${getTypeString(type)} uploaded successfully`);

        return apiResponse.data;

    } catch (error) {
        toastr.error('Problem occured during file upload.');
    }
}

export const addYoutube = async (catId, type, url) => {
    try {
        const apiResponse = await axios.post(`${apiUrl}/cats/${catId}/urls`, {
            title: url,
            type: type,
            catId: catId
        });

        toastr.success(`Youtube video added successfully`);

        return apiResponse.data;

    } catch (error) {
        toastr.error('Problem occured during file upload.');
    }
}

export const deleteFile = async (catId, type, fileId) => {
    try {
        await axios.delete(`${apiUrl}/cats/${catId}/files/${fileId}`);

        toastr.success(`${getTypeString(type)} deleted successfully`);

        return true;
    } catch (error) {
        toastr.error('Problem occured during file deletion.');
    }
}

export const getFriends = async (id, index = 0) => {
    const getFriendsResponse = await axios(`${apiUrl}/cats/${id}/friends?index=${index}`);
    return getFriendsResponse.data;
}

export const getBlogs = async (id, index = 0) => {
    const getBlogsResponse = await axios(`${apiUrl}/cats/${id}/blogs?index=${index}`);
    return getBlogsResponse.data;
}

export const addBlog = async (catId, content, photo) => {
    try {
        const addBlogResponse = await axios.post(`${apiUrl}/cats/${catId}/blogs`, {
            type: feedType.blog,
            content: JSON.stringify(content)
        });

        const blog = addBlogResponse.data;
        blog.files = [];
        blog.comments = [];

        let addFileResponse;
        if (photo) {
            const formData = new FormData();
            formData.append('file', photo);

            addFileResponse = await axios.post(`${apiUrl}/cats/${catId}/blogs/${blog.id}/files`, formData);
            blog.files.push(addFileResponse.data);
        }

        toastr.success(`Cat diary added successfully`);

        return blog;

    } catch (error) {
        toastr.error('Problem occured during adding blog.');
    }
}

export const likeBlog = async (catId, blogId) => {
    try {
        const likeBlogResponse = await axios.post(`${apiUrl}/cats/${catId}/blogs/${blogId}/likes`);
        return likeBlogResponse.data
    } catch (error) {
        toastr.error('Problem occured during adding like.');
    }
}

const getTypeString = (type) => {
    switch (type) {
        case fileType.photo:
            return "Photo";
        case fileType.video:
            return "Video";
        default:
            return "Photo";
    }
}