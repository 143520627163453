import React from 'react';
import UserTimeline from '../../Common/UserTimeline';
import CatList from '../../Common/CatList';
import { ofUser } from '../../../utils/string';

const UserCatsList = ({ user }) => (
    <>
        <div className="main container">
            <div className="timeline">
                <UserTimeline user={user} activeMenu="cats" />
                <div id="page-contents">
                    <div className="row">
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-9" id="my-cats">
                            <h4 className="grey">
                                <i className="ion-ios-heart-outline icon-in-title"></i>
                                {user.isMe ? 'My Cats' : `${ofUser(user)} Cats`}
                            </h4>
                            <div className="line"></div>
                            <CatList cats={user.cats.filter((cat) => !cat.deathdate)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

export default UserCatsList;
