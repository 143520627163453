import axios from 'axios';
import { apiUrl } from '../configuration';

export const getChats = async (catId) => {
    const getChatsResponse = await axios(`${apiUrl}/chats?catId=${catId}`);
    return getChatsResponse.data;
}

export const getMessages = async (chatId, index = 0) => {
    const getMessagesResponse = await axios(`${apiUrl}/chats/${chatId}/messages?index=${index}`);
    return getMessagesResponse.data;
}

export const addMessage = async (chatId, catId, message) => {
    const addMessageResponse = await axios.post(`${apiUrl}/chats/${chatId}/messages`, {
        catId: catId,
        text: message
    });

    return addMessageResponse.data;
}

export const getNewMessages = async (chatId, from) => {
    const getMessageResponse = await axios(`${apiUrl}/chats/${chatId}/messages?from=${from}`);
    return getMessageResponse.data;
}

export const getLastMessage = async (chatId) => {
    const getMessageResponse = await axios(`${apiUrl}/chats/${chatId}/last-message`);
    return getMessageResponse.data;
}

export const readChat = async (chatId, catId) => {
    const readChatResponse = await axios.post(`${apiUrl}/chats/${chatId}/read`, {
        id: catId
    });

    return readChatResponse.data;
}

export const addChat = async (fromCatId, toCatId) => {
    const addChatResponse = await axios.post(`${apiUrl}/chats`, {
        fromCat: { id: fromCatId },
        toCat: { id: toCatId }
    });

    return addChatResponse.data;
}