import React from 'react';
import Footer from './Footer';
import Header from './Header';

const About = () =>
    <>
        <div id="lp-register">
            <div className="wrap">
                <div className="main">
                    <Header />
                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="intro-texts">
                                </div>
                            </div>
                            <div className="col-sm-6 col-sm-offset-1">
                                <div className="panel-group" id="accordion">
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">About</a>
                                            </h4>
                                        </div>
                                        <div id="collapse1" className="panel-collapse collapse in">
                                            <div className="panel-body">
                                                <div id="Translation">
                                                    <p>Whiskersite is a social networking platform for Cats. It aims to provide cats with various communication options via their representatives “CatReps”.</p>
                                                    <p>Whiskersite provides a platform where cat lovers can create a highly categorized documentary of their beloved cat(s) which they can enjoy and share with other cat lovers. This platform also allows its members to share cat related images, videos or information for knowledge or pleasure.</p>
                                                    <p>Among a number of features that are provided in this platform cat lovers who will be operating as CatReps may chose to utilise whichever appeals to them. Some features will stimulate their imagination and creativity.</p>
                                                    <p>Any cat lover who currently has or previously had cat(s) can open a CatRep account. A CatRep account can have multiple cat accounts. Cat lovers whose cat(s) passed away and have no longer have a cat, can also open an account under <strong>​Catchies</strong> and still can be part of this network.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>

export default About;