import React, { useState } from 'react';
import Welcome from './Welcome';

function WelcomeContainer(props) {
    const [activeTab, setActiveTab] = useState('register');

    function onTabChange(activeTab) {
        setActiveTab(activeTab);
    }

    return (
        <Welcome
            activeTab={activeTab}
            onTabChange={onTabChange}
            token={props.match.params.token} />
    )
}

export default WelcomeContainer;
