import React, { useState } from 'react';
import VideoModal from './VideoModal';
import { addFile } from '../../../actions/cat';
import { fileType } from '../../../utils/enum';
import { useHistory } from 'react-router-dom';
import PreLoader from '../../Common/PreLoader';

function VideoModalContainer(props) {
    const [video, setVideo] = useState();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    async function handleVideoUpload(event) {
        const file = event.target.files[0];
        setVideo(file);
    }

    async function saveVideoUpload() {
        setLoading(true);
        await addFile(props.cat.id, fileType.video, video);
        setLoading(false);
        history.push(`/cat-catalog/${props.cat.id}/${props.cat.name}/video`);
        window.location.reload();
    }

    if (loading) {
        return <PreLoader />;;
    }

    return (
        <VideoModal
            handleVideoUpload={handleVideoUpload}
            saveVideoUpload={saveVideoUpload} />
    )
}

export default VideoModalContainer;
