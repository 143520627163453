import React from 'react';
import Validation from '../Validation';

const CatModal = ({ cat, handleChange, onSaveClick, validationErrors, countries }) => {
    return (
        <div className="modal fade add-cat-modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">{cat.id ? 'Edit' : 'Add New'} Cat</h4>
                    </div>
                    <div className="modal-body">
                        <form name="add-cat-form" className="modal-form">
                            <div className="form-group">
                                <label>* Name</label>
                                <input type="text" className="form-control" placeholder="Name" value={cat.name} onChange={value => handleChange('name', value)} />
                            </div>
                            <div className="form-group">
                                <label>Date of birth</label>
                                <div className="form-inline">
                                    <div className="form-group">
                                        <select className="form-control" id="day" value={cat.birthDay} onChange={value => handleChange('birthDay', value)}>
                                            <option value="">Day</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                            <option>13</option>
                                            <option>14</option>
                                            <option>15</option>
                                            <option>16</option>
                                            <option>17</option>
                                            <option>18</option>
                                            <option>19</option>
                                            <option>20</option>
                                            <option>21</option>
                                            <option>22</option>
                                            <option>23</option>
                                            <option>24</option>
                                            <option>25</option>
                                            <option>26</option>
                                            <option>27</option>
                                            <option>28</option>
                                            <option>29</option>
                                            <option>30</option>
                                            <option>31</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select className="form-control" id="month" value={cat.birthMonth} onChange={value => handleChange('birthMonth', value)}>
                                            <option value="">Month</option>
                                            <option value="01">Jan</option>
                                            <option value="02">Feb</option>
                                            <option value="03">Mar</option>
                                            <option value="04">Apr</option>
                                            <option value="05">May</option>
                                            <option value="06">Jun</option>
                                            <option value="07">Jul</option>
                                            <option value="08">Aug</option>
                                            <option value="09">Sep</option>
                                            <option value="10">Oct</option>
                                            <option value="11">Nov</option>
                                            <option value="12">Dec</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select className="form-control" id="year" value={cat.birthYear} onChange={value => handleChange('birthYear', value)}>
                                            <option value="">Year</option>
                                            {[...Array(100)].map((x, index) =>
                                                <option value={new Date().getFullYear() - index} key={index}>{new Date().getFullYear() - index}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group gender">
                                <label>* Gender</label>
                                <div className="form-inline">
                                    <label className="radio-inline">
                                        <input type="radio" name="optradio" checked={cat.isMale === 'on'} onChange={value => handleChange('isMale', value)} />Male
                                </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="optradio" checked={cat.isFemale === 'on'} onChange={value => handleChange('isFemale', value)} />Female
                                </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Place of birth</label>
                                <div className="form-inline">
                                    <div className="form-group">
                                        <select className="form-control" value={cat.countryId} onChange={(value) => handleChange('countryId', value)}>
                                            <option value="">Please select</option>
                                            {countries.map(country =>
                                                <option key={country.id} value={country.id}>{country.title}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="City/Town" value={cat.city} onChange={value => handleChange('city', value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Location</label>
                                <input type="text" className="form-control" placeholder="Location" value={cat.location} onChange={value => handleChange('location', value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="graduate">If passed away:</label>
                                <div className="form-inline">
                                    <div className="form-group">
                                        <label>
                                            <input name="hasPassedAway" type="checkbox" checked={cat.hasPassedAway} onChange={value => handleChange('hasPassedAway', value)} /> Yes
                                        </label>
                                    </div>
                                </div>
                                {cat.hasPassedAway &&
                                    <div>
                                        <label>* Date of death</label>
                                        <div className="form-inline">
                                            <div className="form-group">
                                                <select className="form-control" id="day" value={cat.deathDay} onChange={value => handleChange('deathDay', value)}>
                                                    <option value="">Day</option>
                                                    <option>01</option>
                                                    <option>02</option>
                                                    <option>03</option>
                                                    <option>04</option>
                                                    <option>05</option>
                                                    <option>06</option>
                                                    <option>07</option>
                                                    <option>08</option>
                                                    <option>09</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                    <option>12</option>
                                                    <option>13</option>
                                                    <option>14</option>
                                                    <option>15</option>
                                                    <option>16</option>
                                                    <option>17</option>
                                                    <option>18</option>
                                                    <option>19</option>
                                                    <option>20</option>
                                                    <option>21</option>
                                                    <option>22</option>
                                                    <option>23</option>
                                                    <option>24</option>
                                                    <option>25</option>
                                                    <option>26</option>
                                                    <option>27</option>
                                                    <option>28</option>
                                                    <option>29</option>
                                                    <option>30</option>
                                                    <option>31</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <select className="form-control" id="month" value={cat.deathMonth} onChange={value => handleChange('deathMonth', value)}>
                                                    <option value="">Month</option>
                                                    <option value="0">Jan</option>
                                                    <option value="02">Feb</option>
                                                    <option value="03">Mar</option>
                                                    <option value="04">Apr</option>
                                                    <option value="05">May</option>
                                                    <option value="06">Jun</option>
                                                    <option value="07">Jul</option>
                                                    <option value="08">Aug</option>
                                                    <option value="09">Sep</option>
                                                    <option value="10">Oct</option>
                                                    <option value="11">Nov</option>
                                                    <option value="12">Dec</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <select className="form-control" id="year" value={cat.deathYear} onChange={value => handleChange('deathYear', value)}>
                                                    <option value="">* Year</option>
                                                    {[...Array(100)].map((x, index) =>
                                                        <option key={index} value={new Date().getFullYear() - index}>{new Date().getFullYear() - index}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="form-group">
                                <label>Breed</label>
                                <input type="text" className="form-control" placeholder="Breed" value={cat.breed} onChange={value => handleChange('breed', value)} />
                            </div>
                            <div className="form-group">
                                <label>CatBlog Visibility</label>
                                <div className="form-inline">
                                    <div className="form-group">
                                        <select className="form-control" value={cat.diaryType} onChange={value => handleChange('diaryType', value)}>
                                            <option key="1" value="1">Visible to everyone</option>
                                            <option key="2" value="2">Visible to my CatReps</option>
                                            <option key="3" value="3">Visible only to me</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <span>* required fields</span>
                            </div>
                        </form>
                        <Validation validationErrors={validationErrors} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={onSaveClick}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CatModal;