import React from 'react';
import Login from '../Login';
import Register from '../Register';
import Footer from '../Footer';
import ForgotPassword from '../ForgotPassword';
import ChangePassword from '../ChangePassword';
import TermsAndConditions from '../TermsAndConditions';
import PrivacyAndPolicy from '../PrivacyAndPolicy';

const Welcome = ({ activeTab, onTabChange, token }) =>
    <>
        <div id="lp-register">
            <div className="wrap">
                <div className="main">
                    <header id="header-inverse">
                        <nav>
                            <div className="container">
                                <div className="logo-container">
                                    <a className="logo" href="/"><img className="img-responsive" src="/images/logo-transparent.png" alt="logo" /></a>
                                    <span></span>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="intro-texts">
                                </div>
                            </div>
                            <div className="col-sm-6 col-sm-offset-1">
                                <div className="reg-form-container">
                                    <div className="reg-options">
                                        <ul className="nav nav-tabs">
                                            {!token &&
                                                <>
                                                    <li className={activeTab === 'register' ? 'active' : ''}>
                                                        <a href="#register" data-toggle="tab" onClick={() => onTabChange('register')}>Register</a>
                                                    </li>
                                                    <li className={activeTab === 'login' ? 'active' : ''}>
                                                        <a href="#login" data-toggle="tab" onClick={() => onTabChange('login')}>Login</a>
                                                    </li>
                                                </>
                                            }
                                            {token &&
                                                <li className="active">
                                                    <a href="#login" data-toggle="tab">Change Password</a>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        {!token &&
                                            <>
                                                <Register activeTab={activeTab} onTabChange={onTabChange} />
                                                <Login activeTab={activeTab} onTabChange={onTabChange} />
                                            </>
                                        }
                                        {token && <ChangePassword token={token} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        <ForgotPassword />
        <div className="modal fade terms-modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">Terms & Conditions</h4>
                    </div>
                    <div className="modal-body">
                        <div>
                            <TermsAndConditions />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade privacy-modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">Privacy Policy</h4>
                    </div>
                    <div className="modal-body">
                        <div>
                            <PrivacyAndPolicy />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </>

export default Welcome;