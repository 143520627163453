import React from 'react';
import { userProfile, catProfile } from '../../../../utils/photo';
import { userLink, catLink } from '../../../../utils/link';
import { Link } from 'react-router-dom';

const Search = ({ onChange, results, reset, value }) =>
    <form className="navbar-form navbar-right">
        <div className="form-group">
            <input value={value} onChange={onChange} type="text" className="form-control" placeholder="Search Cat/CatRep" />
        </div>
        {results.length > 0 &&
            <ul className="search-result">
                {results.map((result, index) =>
                    <li key={index}>
                        <div className="row">
                            <div className="col-md-3 col-xs-3">
                                <img src={result.type === 1 ? userProfile(result) : catProfile(result)} alt="user" className="profile-photo-sm" />
                            </div>
                            <div className="col-md-9 col-xs-9">
                                <h5>
                                    {result.type === 1 ?
                                        <Link onClick={reset} className="profile-link" to={userLink(result)}>{`${result.firstname} ${result.lastname}`}</Link> :
                                        <Link onClick={reset} className="profile-link" to={catLink(result)}>{`${result.name}`}</Link>
                                    }

                                    <span className="type"> - {result.type === 1 ? 'CatRep' : 'Cat'}</span>
                                </h5>
                                {result.country && <p>{result.country.title} {result.city ? `- ${result.city}` : ''}</p>}
                            </div>
                        </div>
                    </li>
                )}
            </ul>
        }
    </form>

export default Search;