let apiUrl, staticUrl, socketUrl;

if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://www.whiskersite.com/api';
    staticUrl = 'https://www.whiskersite.com/api/public';
} else {
    apiUrl = 'http://localhost:8080/api';
    staticUrl = 'http://localhost:8080/api/public';
}

const anonymousToken = '7d9f5df5-5361-465c-82ec-2241282125ab';
const defaultUserId = 51;
const catalogVideosEnabled = false;

export { anonymousToken, defaultUserId, apiUrl, socketUrl, staticUrl, catalogVideosEnabled };