import React from 'react';
import Validation from '../../Common/Validation';

const SettingsModal = ({ user, onChange, validationErrors, onSave, countries }) => {
    return (
        <div className="modal fade settings-modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">Edit Settings</h4>
                    </div>
                    <div className="modal-body">
                        <form name="settings-form" className="modal-form">
                            <div className="form-group">
                                <label>* First name</label>
                                <input id="firstname" onChange={value => onChange('firstname', value)} value={user.firstname} className="form-control" type="text" placeholder="Firstname" />
                            </div>
                            <div className="form-group">
                                <label>* Last name</label>
                                <input id="lastname" onChange={value => onChange('lastname', value)} value={user.lastname} className="form-control" type="text" placeholder="Firstname" />
                            </div>
                            <div className="form-group">
                                <label>* Country</label>
                                <select className="form-control" id="country" value={user.countryId} onChange={(value) => onChange('countryId', value)}>
                                    <option>Please select</option>
                                    {countries.map(country =>
                                        <option key={country.id} value={country.id}>{country.title}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>City/Town (Optional)</label>
                                <input id="city" value={user.city} onChange={value => onChange('city', value)} className="form-control" type="text" name="city" title="City" placeholder="City/Town (Optional)" />
                            </div>
                        </form>
                        <Validation validationErrors={validationErrors} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={onSave}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsModal;