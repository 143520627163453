import React from 'react';
import { Link } from 'react-router-dom';
import { userProfile } from '../../../utils/photo';
import PhotoModal from '../PhotoModal';
import { userLink, isDefaultUser } from '../../../utils/link';
import { ofUser } from '../../../utils/string';
import MemberOnly from '../../Common/MemberOnly';
import Tooltip from '../Tooltip';

const UserTimeline = ({ user, activeMenu, onConnect, onSaveProfilePhoto }) => {
    const getCssClass = (menuName) => {
        return activeMenu === menuName ? "active" : null
    }

    return (
        <>
            <Tooltip type="user" />
            <PhotoModal modalClass="add-photo-modal" onSave={onSaveProfilePhoto} />
            <div className="timeline-cover">
                <div className="timeline-nav-bar hidden-sm hidden-xs">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="profile-info">
                                <MemberOnly>
                                    <a href="#/" className="profile-photo-link" data-toggle={user.isMe ? 'modal' : ''} data-target={user.isMe ? '.add-photo-modal' : ''}>
                                        <img id="profile-photo-image" src={userProfile(user)} alt="" className="img-responsive profile-photo" />
                                    </a>
                                </MemberOnly>
                                <h3>{user.firstname} {user.lastname}</h3>
                                {!isDefaultUser(user) && <p className="text-muted">{user.country.title} {user.city ? `- ${user.city}` : ''}</p>}
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="tooltip_templates" style={{ display: 'none' }}>
                                <span id="catchies-tooltip">
                                    <div className="tooltip-content">
                                        <img className="img-responsive" src="/images/hover-ribbon.png" alt="" />
                                        <div>
                                            <span>Photographs or videos of cats that passed away will be shared here</span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <ul className="list-inline profile-menu">
                                <li><Link id="favorites-link" className={getCssClass("about")} to={userLink(user)}>
                                    {`${user.isMe ? 'My' : ofUser(user)} Favorites`}</Link>
                                </li>
                                <li>
                                    <Link id="my-cats-link" className={getCssClass("cats")} to={`/my-cats/${user.id}`}>
                                        {`${user.isMe ? 'My' : ofUser(user)} Cats`}
                                    </Link>
                                </li>
                                <li className="custom-tooltip" data-tooltip-content="#catchies-tooltip">
                                    <Link id="catchies-link" className={getCssClass("catchies")} to={`/catchies/${user.id}`}>
                                        Catchies
                                    </Link>
                                </li>
                                <li>
                                    <Link id="catreps-link" className={getCssClass("friends")} to={`/cat-reps/${user.id}`}>
                                        CatReps
                                    </Link>
                                </li>
                            </ul>
                            <ul className="follow-me list-inline">
                                {user.isMe && activeMenu === 'about' &&
                                    <li>
                                        <MemberOnly>
                                            <button id="addcat-button" className="btn-primary" data-toggle="modal" data-target=".add-cat-modal">Add Cat</button>
                                        </MemberOnly>
                                    </li>
                                }
                                {!user.isMe && !user.isFriend && <li>
                                    <MemberOnly>
                                        <button className="btn-primary" onClick={onConnect}>Connect</button>
                                    </MemberOnly>
                                </li>}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="navbar-mobile hidden-lg hidden-md">
                    <div className="profile-info">
                        <MemberOnly>
                            <a href="#/" className="profile-photo-link" data-toggle={user.isMe ? 'modal' : ''} data-target={user.isMe ? '.add-photo-modal' : ''}>
                                <img id="profile-photo-image-sm" src={userProfile(user)} alt="" className="img-responsive profile-photo" />
                            </a>
                        </MemberOnly>
                        <h4>{user.firstname} {user.lastname}</h4>
                        {!isDefaultUser(user) && <p className="text-muted">{user.country.title} {user.city ? `- ${user.city}` : ''}</p>}
                    </div>
                    <div className="mobile-menu">
                        <ul className="list-inline">
                            <li><Link id="favorites-link-sm" className={getCssClass("about")} to={userLink(user)}>{`${user.isMe ? 'My' : ofUser(user)} Favorites`}</Link></li>
                            <li><Link id="my-cats-link-sm" className={getCssClass("cats")} to={`/my-cats/${user.id}`}>{`${user.isMe ? 'My' : ofUser(user)} Cats`}</Link></li>
                            <li className="custom-tooltip" data-tooltip-content="#catchies-tooltip">
                                <Link id="catchies-link-sm" className={getCssClass("catchies")} to={`/catchies/${user.id}`}>Catchies</Link>
                            </li>
                            <li><Link id="catreps-link-sm" className={getCssClass("friends")} to={`/cat-reps/${user.id}`}>CatReps</Link></li>
                        </ul>
                        <ul className="follow-me list-inline">
                            {user.isMe && activeMenu === 'about' && <li>
                                <MemberOnly>
                                    <button id="addcat-button-sm" className="btn-primary" data-toggle="modal" data-target=".add-cat-modal">Add Cat</button>
                                </MemberOnly>
                            </li>}
                            {!user.isMe && !user.isFriend && <li>
                                <MemberOnly>
                                    <button className="btn-primary" onClick={onConnect}>Connect</button>
                                </MemberOnly>
                            </li>}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserTimeline;