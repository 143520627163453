import React, { useContext, useEffect, useState } from 'react';
import List from './List';
import { getFriends, getUsers } from '../../../actions/user';
import Scrollable from '../../Common/Scrollable';
import Context from '../../../context';
import { useHistory, useParams } from 'react-router-dom';

function ListContainer() {
    const { type } = useParams();
    const { current } = useContext(Context);
    const [users, setUsers] = useState([]);
    const [friends, setFriends] = useState([]);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const fetchedUsers = await getUsers(current, type);
            setUsers(fetchedUsers);
            const fetchedFriends = await getFriends(current.id);
            setFriends(fetchedFriends);
        })();
    }, [current, type])

    function onFilterChange(event) {
        history.push(`/new-cat-reps/${event.target.value}`)
    }

    async function callback(index) {
        const newUsers = await getUsers(current, type, index);
        setUsers([
            ...users,
            ...newUsers
        ]);
    }

    function filterUsers(users) {
        return users.filter((user) => user.id !== current.id)
            .map((user) => {
                const isFriend = friends.filter((friend) => friend.id === user.id).length > 0;
                return isFriend ? null : user;
            })
            .filter((user) => user);
    }

    return (
        <Scrollable count={users.length} callback={callback}>
            <List
                current={current}
                users={filterUsers(users)}
                onFilterChange={onFilterChange} />
        </Scrollable>
    )
}

export default ListContainer;
