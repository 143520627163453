import React from 'react';

const DeleteComment = ({ comment, onDelete }) => {
    return (
        <div className={`modal fade delete-comment-${comment.id}-modal`} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">Delete comment</h4>
                    </div>
                    <div className="modal-body">
                        <form name="delete-comment-form" className="modal-form">
                            <div className="form-group">
                                <p>Are you sure you want to delete this comment?</p>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={onDelete}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default DeleteComment;
