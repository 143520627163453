import React, { Fragment } from 'react';
import CatTimeline from '../../Common/CatTimeline';
import { Link } from 'react-router-dom';
import { catProfile } from '../../../utils/photo';
import timeAgo from '../../../utils/timeAgo';
import { userLink } from '../../../utils/link';

const Chat = ({ cat, chats, activeChat, messages, handleChange, sendMessage, inputText, scrollRef, setChat, handleKeyPress, inputRef }) => (
    <Fragment>
        <div className="main container">
            <div className="timeline">
                <CatTimeline cat={cat} activeMenu="chat" />
                <div id="page-contents">
                    <div className="row">
                        <div className="col-md-3 static">
                            <ul className="edit-menu">
                                <li className="active"><i className="icon ion-ios-information-outline"></i>
                                    <Link to={userLink(cat.user)}>Back to CatRep</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-9" id="cat-chat">
                            <h4 className="grey">
                                <i className="ion-ios-heart-outline icon-in-title"></i>CatChat
                            </h4>
                            <div className="line"></div>
                            <div className="chat-room">
                                <div className="row">
                                    <div className="col-md-5">
                                        <ul className="nav nav-tabs contact-list scrollbar-wrapper scrollbar-outer">
                                            {chats.map((chat, index) =>
                                                <li key={index} className={activeChat.id === chat.id ? "active" : null}>
                                                    <a href="#/" onClick={() => setChat(chat.id)}>
                                                        <div className="contact">
                                                            <img src={catProfile(chat.cat)} alt="" className="profile-photo-sm pull-left" />
                                                            <div className="msg-preview">
                                                                <h6>{chat.cat.name}</h6>
                                                                <p className="text-muted">
                                                                    {chat.lastMessage && chat.lastMessage.text}
                                                                </p>
                                                                <small className="text-muted">{timeAgo(chat.lastMessage && chat.lastMessage.createdAt)}</small>
                                                                {chat.unreadCount > 0 && <div className="chat-alert">{chat.unreadCount}</div>}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="col-md-7">
                                        <div ref={scrollRef} className="tab-content scrollbar-wrapper wrapper scrollbar-outer">
                                            <div className="tab-pane active" id="contact-1">
                                                <div className="chat-body">
                                                    <ul className="chat-message">
                                                        {messages.map((message) => {
                                                            const className = message.isLeft ? "left" : "right";
                                                            return (
                                                                <li key={message.id} className={className}>
                                                                    <img src={catProfile(message.cat)} alt="" className={`profile-photo-sm pull-${className}`} />
                                                                    <div className="chat-item">
                                                                        <div className="chat-item-header">
                                                                            <h5>{message.cat.name}</h5>
                                                                            <small className="text-muted">{timeAgo(message.createdAt)}</small>
                                                                        </div>
                                                                        <p>{message.text}</p>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="send-message">
                                            <div className="input-group">
                                                <input ref={inputRef} onChange={handleChange} onKeyPress={handleKeyPress} value={inputText} type="text" className="form-control" placeholder="Type your message" />
                                                <span className="input-group-btn">
                                                    <button onClick={sendMessage} className="btn btn-default" type="button">Send</button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
)

export default Chat;
