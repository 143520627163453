import React from 'react';

const VideoModal = ({ handleVideoUpload, saveVideoUpload }) =>
    <div className="modal fade add-video-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title">Upload video</h4>
                </div>
                <div className="modal-body">
                    <p>
                        <span className="btn btn-default btn-file">
                            Choose your video <input type="file" onChange={handleVideoUpload} />
                        </span>
                    </p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveVideoUpload}>Save changes</button>
                </div>
            </div>
        </div>
    </div>

export default VideoModal;