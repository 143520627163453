import React from 'react';
import Validation from '../../Common/Validation';

const Login = ({ handleChange, onLoginClick, validationErrors, activeTab, onTabChange, onKeyDown }) =>
    <div className={`tab-pane ${activeTab === 'login' ? 'active' : ''}`} id="login">
        <h3>Login</h3>
        <p className="text-muted">Log into your account</p>
        <form name="Login_form" id='Login_form'>
            <div className="row">
                <div className="form-group col-xs-12">
                    <label htmlFor="my-email" className="sr-only">Email</label>
                    <input id="login-email" onChange={value => handleChange('email', value)} onKeyDown={onKeyDown} className="form-control input-group-lg" type="text" name="Email" title="Enter Email" placeholder="Your Email" />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-xs-12">
                    <label htmlFor="my-password" className="sr-only">Password</label>
                    <input id="login-password" onChange={value => handleChange('password', value)} onKeyDown={onKeyDown} className="form-control input-group-lg" type="password" name="password" title="Enter password" placeholder="Password" autoComplete="off" />
                </div>
            </div>
        </form>
        <Validation validationErrors={validationErrors} />
        <p><a href="#/" onClick={() => onTabChange('register')}>Do you want create an account?</a></p>
        <button className="btn btn-primary margin-right-20" onClick={onLoginClick}>Login Now</button>
        <a href="#/" data-toggle="modal" data-target=".forgot-password">I forgot my password</a>
    </div>
export default Login;                            