import React, { useContext, useState } from 'react';
import { ruleRunner, run } from '../../../utils/validation';
import { required, minLength, mustMatch } from '../../../utils/validation/rules';
import PasswordModal from './PasswordModal';
import { userLink } from '../../../utils/link';
import { changePassword } from '../../../actions/user';
import Context from '../../../context';
import { useHistory } from 'react-router-dom';

const emptyState = {
    password: '',
    password2: ''
};

function PasswordModalContainer() {
    const [model, setModel] = useState(emptyState);
    const [validationErrors, setValidationErrors] = useState();

    const { current } = useContext(Context);

    const history = useHistory();

    function onChange(field, value) {
        setModel({
            ...model,
            [field]: value.target.value
        });
    }

    async function onSave() {
        const validationErrors = run(model, fieldValidations);

        if (Object.keys(validationErrors).length > 0) {
            setValidationErrors(validationErrors);
        }
        else {
            setValidationErrors(null);
            const response = await changePassword(current, model.password);
            if (response) {
                window.hideModal();
                history.push(userLink(current));
            }
        }
    }

    return (
        <PasswordModal
            validationErrors={validationErrors}
            onChange={onChange}
            onSave={onSave} />
    )
}

const fieldValidations = [
    ruleRunner("password", "Password", required, minLength(3)),
    ruleRunner("password2", "Password confirmation", required, mustMatch("password", "Password")),
];

export default PasswordModalContainer;
