import React, { useContext, useEffect, useState } from 'react';
import Information from './Information';
import { getCat } from '../../../actions/cat';
import { useParams } from 'react-router-dom';
import Context from '../../../context';
import PreLoader from '../../Common/PreLoader';

function InformationContainer() {
    const { id } = useParams();
    const [cat, setCat] = useState();
    const { current } = useContext(Context);

    useEffect(() => {
        (async () => {
            const fetchedCat = await getCat(id, current);
            setCat(fetchedCat);
        })();
    }, [id, current]);

    if (!cat) {
        return <PreLoader />;;
    }

    return (
        <>
            <Information
                cat={cat} />
        </>
    )
}

export default InformationContainer;
