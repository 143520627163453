import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Welcome from './components/Welcome/Welcome';
import About from './components/Welcome/About';
import Guide from './components/Welcome/Guide';
import Terms from './components/Welcome/Terms';
import Privacy from './components/Welcome/Privacy';
import Contact from './components/Welcome/Contact';
import Validate from './components/Welcome/Validate';
import App from './components/App';
import Context from './context';

function Root() {
  const [current, setCurrent] = useState();

  return (
    <Context.Provider value={{ current, setCurrent }}>
      <Router>
        <Switch>
          <Route path="/welcome/:token?" component={Welcome} />
          <Route path="/about" component={About} />
          <Route path="/guide" component={Guide} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/contact" component={Contact} />
          <Route path="/validate/:token" component={Validate} />
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </Context.Provider>
  );
}

export default Root;
