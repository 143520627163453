import React from 'react';
import Footer from './Footer';
import Header from './Header';
import TermsAndConditions from './TermsAndConditions';

const Terms = () =>
    <>
        <div id="lp-register">
            <div className="wrap">
                <div className="main">
                    <Header />
                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="intro-texts">
                                </div>
                            </div>
                            <div className="col-sm-6 col-sm-offset-1">
                                <div className="panel-group" id="accordion">
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Terms and Conditions</a>
                                            </h4>
                                        </div>
                                        <div id="collapse1" className="panel-collapse collapse in">
                                            <div className="panel-body">
                                                <div id="Translation">
                                                    <TermsAndConditions />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>

export default Terms;