import React, { useContext } from 'react';
import Header from './Header';
import { signOut } from '../../../actions/user';
import Context from '../../../context';
import { useHistory } from 'react-router-dom';

function HeaderContainer() {
    const { current, setCurrent } = useContext(Context);
    const history = useHistory();

    function getUnreadChats() {
        return current.unreadChats
            .map((chat) => {
                if (chat.toUnreadCount > 0 && chat.toCat.user.id === current.id) {
                    return {
                        unreadCount: chat.toUnreadCount,
                        cat: chat.toCat
                    }
                }

                if (chat.fromUnreadCount > 0 && chat.fromCat.user.id === current.id) {
                    return {
                        unreadCount: chat.fromUnreadCount,
                        cat: chat.fromCat
                    }
                }

                return null;
            });
    }

    function signOutUser() {
        signOut(setCurrent);
        history.push('/welcome');
    }

    return (
        <Header
            user={current}
            signOut={signOutUser}
            unreadChats={getUnreadChats()} />
    )
}

export default HeaderContainer;
