import React, { useState } from 'react';
import { contact } from '../../../actions/user';
import Contact from './Contact';
import { ruleRunner, run } from '../../../utils/validation';
import { required, email } from '../../../utils/validation/rules';
import { useHistory } from 'react-router-dom';

function ContactContainer() {
    const [model, setModel] = useState({});
    const [validationErrors, setValidationErrors] = useState();
    const history = useHistory();

    function handleChange(field, value) {
        setModel({
            ...model,
            [field]: value.target.value
        });
    }

    async function onContactClick() {
        const newValidationErrors = run(model, fieldValidations);

        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        }
        else {
            setValidationErrors(null);
            const response = await contact(model);
            if (response) {
                history.push('/information');
            }
        }
    }

    return (
        <Contact
            handleChange={handleChange}
            onContactClick={onContactClick}
            validationErrors={validationErrors} />
    )
}

const fieldValidations = [
    ruleRunner("name", "Name", required),
    ruleRunner("email", "Email", required, email),
    ruleRunner("subject", "Subject", required),
    ruleRunner("message", "Message", required),

];

export default ContactContainer;