import React, { useContext, useEffect, useState } from 'react';
import { getCountries } from '../../../actions/country';
import { register, signOut } from '../../../actions/user';
import Register from './Register';
import { ruleRunner, run } from '../../../utils/validation/';
import { required, minLength, mustMatch, email, shouldBeTrue } from '../../../utils/validation/rules';
import Context from '../../../context';
import { Redirect } from 'react-router-dom';

function RegisterContainer(props) {
    const { setCurrent } = useContext(Context);
    const [model, setModel] = useState({});
    const [countries, setCountries] = useState([]);
    const [validationErrors, setValidationErrors] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        (async () => {
            const countries = await getCountries();
            setCountries(countries);
        })();
    }, []);

    function handleChange(field, value) {
        const inputValue = value.target.type === 'checkbox' ? value.target.checked : value.target.value;
        setModel({
            ...model,
            [field]: inputValue
        });
    }

    async function onRegisterClick() {
        const newValidationErrors = run(model, fieldValidations);

        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        }
        else {
            if (!model.isMale && !model.isFemale) {
                setValidationErrors({ gender: 'Please choose your gender' });
            }
            else {
                setValidationErrors(null);
                await signOut(setCurrent);
                const hasRegistered = await register(model);
                if (hasRegistered) {
                    setIsLoggedIn(true);
                }
            }
        }
    }

    if (isLoggedIn) {
        return <Redirect to='/information' />
    }

    return (
        <Register
            countries={countries}
            handleChange={handleChange}
            onRegisterClick={onRegisterClick}
            validationErrors={validationErrors}
            activeTab={props.activeTab}
            onTabChange={props.onTabChange} />
    )
}

const fieldValidations = [
    ruleRunner("firstname", "First name", required),
    ruleRunner("email", "Email", email),
    ruleRunner("password", "Password", required, minLength(3)),
    ruleRunner("password2", "Password confirmation", required, mustMatch("password", "Password")),
    ruleRunner("day", "Day", required),
    ruleRunner("month", "Month", required),
    ruleRunner("year", "Year", required),
    ruleRunner("countryId", "Country", required),
    ruleRunner("hasAgreed", "Terms and Conditions", shouldBeTrue),
];

export default RegisterContainer;