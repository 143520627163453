import React, { useContext, useState } from 'react';
import { login, signOut } from '../../../actions/user';
import Login from './Login';
import { ruleRunner, run } from '../../../utils/validation/';
import { required, email } from '../../../utils/validation/rules';
import { Redirect } from 'react-router-dom';
import Context from '../../../context';

function LoginContainer(props) {
    const { setCurrent } = useContext(Context);
    const [model, setModel] = useState({});
    const [validationErrors, setValidationErrors] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    function handleChange(field, value) {
        setModel({
            ...model,
            [field]: value.target.value
        });
    }

    async function onLoginClick() {
        const newValidationErrors = run(model, fieldValidations);
        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        }
        else {
            setValidationErrors(null);
            signOut(setCurrent);
            const hasLoggedIn = await login(model.email, model.password);
            if (hasLoggedIn) {
                setIsLoggedIn(true);
            }
        }
    }

    function onKeyDown(event) {
        if (event.key === 'Enter') {
            onLoginClick();
        }
    }

    if (isLoggedIn) {
        return <Redirect to='/information' />
    }

    return (
        <Login
            handleChange={handleChange}
            onLoginClick={onLoginClick}
            validationErrors={validationErrors}
            activeTab={props.activeTab}
            onTabChange={props.onTabChange}
            onKeyDown={onKeyDown} />
    )
}

const fieldValidations = [
    ruleRunner("email", "Email", email),
    ruleRunner("password", "Password", required)
];

export default LoginContainer;
