import React from 'react';
import { Link } from 'react-router-dom';
import { catProfile } from '../../utils/photo';
import { catLink } from '../../utils/link';

const CatList = ({ cats }) =>
    <div className="friend-list">
        <div className="row">
            {cats.map((cat) =>
                <div key={cat.id} className="col-md-6 col-sm-6">
                    <div id={`cat-${cat.id}`} className="friend-card">
                        <Link className="profile-link" to={catLink(cat)}>
                            <img src="/images/blue-background.jpeg" alt="profile-cover" className="img-responsive cover" />
                        </Link>
                        <div className="card-info">
                            <Link className="profile-link" to={catLink(cat)}>
                                <img src={catProfile(cat)} alt="user" className="profile-photo-lg" />
                            </Link>
                            <div className="friend-info">
                                <h5>
                                    <Link className="profile-link" to={catLink(cat)}>{cat.name}</Link>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </div>

export default CatList;