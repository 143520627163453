import React from 'react';
import { startTour } from '../../utils/link';

class Footer extends React.Component {
    onIntroClick(event) {
        startTour();    
    }

    render() {
        return (
            <footer id="welcome-footer">
                <div className="container">
                    <div className="row">
                        <div className="footer-wrapper">
                            <div className="col-md-3 col-sm-3 col-xs-3">
                                <h5><a href="/about">About</a></h5>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-2">
                                <h5><a href="#/" onClick={this.onIntroClick}>Site Tour</a></h5>
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-3">
                                <h5><a href="/terms">Terms</a></h5>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-2">
                                <h5><a href="/privacy">Privacy</a></h5>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-2">
                                <h5><a href="/contact">Contact</a> </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <p>whiskersite © 2020. All rights reserved</p>
                </div>
            </footer>
        )
    }
}


export default Footer;