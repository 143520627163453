import React from 'react';
import Validation from '../../Validation';

const EditComment = ({ comment, onInputChange, message, validationErrors, onSave }) =>
    <div className={`modal fade edit-comment-${comment.id}-modal`} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title">Edit comment</h4>
                </div>
                <div className="modal-body">
                    <form name="edit-comment-form" className="modal-form">
                        <div className="form-group">
                            <textarea onChange={value => onInputChange('message', value)} value={message} rows="5" name="texts" className="form-control" placeholder=""></textarea>
                        </div>
                    </form>
                    <Validation validationErrors={validationErrors} />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={onSave}>Save changes</button>
                </div>
            </div>
        </div>
    </div>

export default EditComment;