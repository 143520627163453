import React, { useCallback, useContext, useEffect, useState } from 'react';
import { addFile, getCat, getFiles, deleteFile } from '../../../actions/cat';
import Catalog from './Catalog';
import { fileType } from '../../../utils/enum';
import Scrollable from '../../Common/Scrollable';
import { useHistory, useParams } from 'react-router-dom';
import Context from '../../../context';
import PreLoader from '../../Common/PreLoader';

function CatalogContainer() {
    const { id, type } = useParams();
    const [cat, setCat] = useState();
    const [videos, setVideos] = useState([]);
    const [youtubeUrls, setYoutubeUrls] = useState([]);
    const [photos, setPhotos] = useState([]);
    const { current } = useContext(Context);
    const history = useHistory();

    const loadFilesCallback = useCallback(async (index) => {
        if (type === 'video') {
            const fetchedVideos = await getFiles(id, fileType.video, index);
            setVideos(videos => [
                ...videos,
                ...fetchedVideos
            ]);
        }
        else if (type === 'youtube') {
            const fetchedYoutubeUrls = await getFiles(id, fileType.youtube, index);
            setYoutubeUrls(youtubeUrls => [
                ...youtubeUrls,
                ...fetchedYoutubeUrls
            ]);
        } else {
            const fetchedPhotos = await getFiles(id, fileType.photo, index);
            setPhotos(photos => [
                ...photos,
                ...fetchedPhotos
            ]);
        }
    }, [id, type]);

    useEffect(() => {
        (async () => {
            const fetchedCat = await getCat(id, current);
            setCat(fetchedCat);
            await loadFilesCallback();
        })();
    }, [id, current, loadFilesCallback]);

    async function handleDelete(photoId) {
        await deleteFile(id, fileType.photo, photoId);
        setPhotos(photos.filter((item) => item.id !== photoId));
        window.hideModal();
    }

    async function handleDeleteVideo(videoId) {
        await deleteFile(id, fileType.video, videoId);
        history.push(`/cat-catalog/${id}/${cat.name}/video`);
        window.location.reload();
    }

    async function handleDeleteYoutube(videoId) {
        await deleteFile(id, fileType.youtube, videoId);
        history.push(`/cat-catalog/${cat.id}/${cat.name}/youtube`);
        window.location.reload();
    }

    async function handlePhotoSave(profilePhoto) {
        const catalogPhoto = await addFile(cat.id, fileType.photo, profilePhoto);
        setPhotos([
            catalogPhoto,
            ...photos
        ]);
    }

    function getCount() {
        if (type === fileType.video) {
            return videos.length;
        }
        else if (type === fileType.youtube) {
            return youtubeUrls.length;
        } else {
            return photos.length;
        }
    }

    async function getNewFiles(index) {
        await loadFilesCallback(index);
    }

    if (!cat) {
        return <PreLoader />;;
    }

    return (
        <Scrollable count={getCount()} callback={getNewFiles}>
            <Catalog
                cat={cat}
                current={current}
                videos={videos}
                youtubeUrls={youtubeUrls}
                photos={photos}
                handleDelete={handleDelete}
                fileType={type}
                handleDeleteVideo={handleDeleteVideo}
                handleDeleteYoutube={handleDeleteYoutube}
                onPhotoSave={handlePhotoSave}
            />
        </Scrollable>
    )
}

export default CatalogContainer;