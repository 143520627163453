import React from 'react';
import CatTimeline from '../../Common/CatTimeline';
import { Link } from 'react-router-dom';
import { userLink } from '../../../utils/link';

const EmptyChat = ({ cat }) =>
    <div className="container">
        <div className="timeline">
            <CatTimeline cat={cat} activeMenu="chat" />
            <div id="page-contents">
                <div className="row">
                    <div className="col-md-3 static">
                        <ul className="edit-menu">
                            <li className="active"><i className="icon ion-ios-information-outline"></i>
                                <Link to={userLink(cat.user)}>Back to CatRep</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-9">
                        <div className="about-profile">
                            <div className="about-content-block">
                                <h4 className="grey"><i className="ion-ios-heart-outline icon-in-title"></i>CatChat</h4>
                            </div>
                        </div>
                        <div>
                            <h4 className="grey">You didn't start any chat yet.</h4>
                            <h4>Please click "Start CatChat" button to start new conversation.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

export default EmptyChat;