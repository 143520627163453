import React from 'react';

class Masonry extends React.Component {
    constructor(props) {
        super(props);
        this.state = { columns: 1 };
        this.brakePoints = [350, 500, 750];
        this.onResize = this.onResize.bind(this);
        this.ref = React.createRef();
    }
    componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize)
    }

    getColumns(w) {
        return this.brakePoints.reduceRight((p, c, i) => {
            return c < w ? p : i;
        }, this.brakePoints.length) + 1;
    }

    onResize() {
        if (this.ref.current) {
            const columns = this.getColumns(this.ref.current.offsetWidth);
            if (columns !== this.state.columns) {
                this.setState({ columns: columns });
            }
        }
    }

    mapChildren() {
        let col = [];
        const numC = this.state.columns;
        for (let i = 0; i < numC; i++) {
            col.push([]);
        }
        return this.props.children.reduce((p, c, i) => {
            p[i % numC].push(c);
            return p;
        }, col);
    }

    render() {
        return (
            <div className="masonry" ref={this.ref}>
                {this.mapChildren().map((col, ci) => {
                    return (
                        <div className="column" key={ci} >
                            {col.map((child, i) => {
                                return <div key={i} >{child}</div>
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default Masonry;