import { anonymousToken, defaultUserId } from '../configuration';

export const catLink = (cat) => {
    if (!cat) {
        return '/';
    }
    if (!cat.name) {
        return `/cat/${cat.id}`;
    }
    return `/cat/${cat.id}/${cat.name.replace(/ /g, '-')}`;
}

export const userLink = (user) => {
    if (!user) {
        return '/';
    }
    return `/cat-rep/${user.id}/${user.firstname}-${user.lastname}`;
}

export const isPending = () => {
    return window.localStorage.getItem('status') === '0';
}

export const isAnonymous = () => {
    return window.localStorage.getItem('token') === anonymousToken;
}

export const isDefaultUser = (user) => {
    return user.id === defaultUserId;
}

export const startTour = () => {
    removeCookieAndStorage();
    window.location.href = "/welcome";
}

const removeCookieAndStorage = () => {
    const expire = new Date();
    expire.setDate(expire.getDate() + 365 * 10);
    document.cookie = 'hasIntroShown=false; expires=' + expire.toUTCString() + '; path=/';
    window.localStorage.clear();
}