import React from 'react';
import { isAnonymous, isPending } from '../../utils/link';

class MemberOnly extends React.Component {
    constructor(props) {
        super(props);
        this.disallow = Boolean(isAnonymous()) || Boolean(isPending());
    }

    onClick(event) {
        event.preventDefault();
    }

    render() {
        const { children, hide } = this.props;
        if (this.disallow && hide) {
            return null;
        }

        const filtered = React.cloneElement(children,
            {
                onClick: this.onClick.bind(this),
                'data-toggle': 'modal',
                'data-target': '.anonymous-modal',
                title: 'This functionality is disabled for the guest user'
            });

        return (
            <>
                {this.disallow ? filtered : children}
            </>
        )
    }
}

export default MemberOnly;