import React, { useState } from 'react';
import Search from './Search';
import { searchUsers } from '../../../../actions/user';
import { searchCats } from '../../../../actions/cat';

function SearchContainer() {
    const [value, setValue] = useState('');
    const [result, setResult] = useState([]);

    async function onChange(event) {
        const value = event.target.value;
        const users = await searchUsers(value);
        const cats = await searchCats(value);
        const result = [
            ...users.map(user => { return { ...user, type: 1 } }),
            ...cats.map(cat => { return { ...cat, type: 2 } })
        ].sort((a, b) => b.createdAt - a.createdAt);

        setValue(value);
        setResult(result);
    }

    function reset() {
        setValue('');
        setResult([]);
    }

    return (
        <Search
            value={value}
            onChange={onChange}
            results={result}
            reset={reset} />
    )
}

export default SearchContainer;
